import { FinanceProgramFormValues } from "../types";
import { ValueOf } from "../../../global";

export function convertProgramConfigToFormValues(
  programConfig: any
): Partial<ValueOf<FinanceProgramFormValues, "financeQuote">> {
  const isCommonRate = new Set(programConfig.terms.map((termValue: any) => termValue?.rate)).size === 1;

  return {
    rateType: programConfig.rateType,
    programName: programConfig.financeProgramName,
    paymentCalculationMethod: programConfig.paymentPeriodTiming,
    terms: programConfig.terms
      .map((termValue: any) => (termValue?.term ? String(termValue?.term) : ""))
      .filter(Boolean),
    commonRate: isCommonRate ? String(programConfig.terms[0].rate) : "",
    specifyRates: !isCommonRate
      ? programConfig.terms.map((termValue: any) => ({ term: String(termValue?.term), rate: String(termValue?.rate) }))
      : [],
  };
}
