import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Box, Divider, IconButton, Typography } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { ProposalProductAddon } from "@trnsact/trnsact-shared-types/src/generated";

interface Props {
  addons: ProposalProductAddon[];
  onRemoveAddon: (category: string) => void;
}

export const ProposalProductCardAddons = ({ onRemoveAddon, addons = [] }: Props) => {
  const classes = useStyles();

  const groupedAddonsByCategory = useMemo(
    () =>
      addons.reduce<Record<string, ProposalProductAddon[]>>((acc, addon) => {
        if (!addon) return acc;

        const key = addon.category ? addon.category : "Addons";

        if (acc[key]) acc[key].push(addon);
        else acc[key] = [addon];

        return acc;
      }, {}),
    [addons]
  );

  return (
    <>
      {Object.entries(groupedAddonsByCategory).map(([category, addons], index) => (
        <Box key={category} className={classes.container}>
          <Divider orientation="horizontal" variant="fullWidth" flexItem style={{ height: "1px" }} />

          <Box className={classes.groupTitle}>
            <Typography component="span" variant="subtitle2">
              {category}
            </Typography>

            <Box className="actions">
              <InfoOutlined color="primary" />

              <IconButton
                size="small"
                onClick={event => {
                  event.stopPropagation();
                  onRemoveAddon(category);
                }}
              >
                <DeleteOutlineIcon color="error" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      ))}
    </>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "4px",
    display: "flex",
    flexDirection: "column",
  },
  groupTitle: {
    gap: "2px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
