import React, { FC } from "react";
import { NTPProductCard, SimpleProductCard } from "./products";
import { AftermarketProductVendorApiChannel, ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { AftermarketProductTypes, ProposalProductCardProps } from "../../../types";

interface Props {
  type: "simple" | "forEdit";
  product: ProposalProduct;
}

const ProposalProductsCards = ({ product, type }: Props) => {
  const productsCards: Record<AftermarketProductTypes, FC<ProposalProductCardProps>> = {
    [AftermarketProductTypes.Simple]: SimpleProductCard,
    [AftermarketProductTypes.Ntp]: NTPProductCard,
    [AftermarketProductTypes.Gaig]: () => <>Gaig</>,
  };

  // TODO: refactor this, this was for the demo on 10/10/24
  const cardType =
    product.aftermarketProduct?.aftermarketVendorApiChannel === AftermarketProductVendorApiChannel.Ntp &&
    product?.aftermarketProduct?.criteria[0].event.params.AFTERMARKET_PRODUCT_VENDOR_API_DATA
      ? AftermarketProductVendorApiChannel.Ntp
      : AftermarketProductTypes.Simple;
  const Card = productsCards[cardType];

  return <Card type={type} proposalProduct={product} />;
};

export default ProposalProductsCards;
