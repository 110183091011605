import React from "react";
import { Switch, SwitchProps, FormControlLabel } from "@material-ui/core";

interface Props extends SwitchProps {
  label?: string;
}

export const FormSwitch = ({ label, ...switchProps }: Props) => {
  return <FormControlLabel control={<Switch color="primary" {...switchProps} />} label={label} />;
};
