import React, { Fragment, ReactNode } from "react";
import { useSelector } from "react-redux";
import { ModalsKeys } from "../../../../components/shared/Modals";
import { modalsSelectors } from "../../../../redux/modalsReducer";
import { ConfirmSaveDraftDialog, ProposalProductsDialog, ProductCatalogDialog } from "../dialogs";
import { FinanceProgramFormContainer } from "./form/FinanceProgramFormContainer";

export const FinanceProgram = () => {
  const openedModals = useSelector(modalsSelectors.openedModals) as ModalsKeys[];

  const modalsWithKeys: Partial<Record<ModalsKeys, ReactNode>> = {
    [ModalsKeys.DeskingConfirmSaveDraftDialog]: <ConfirmSaveDraftDialog />,
    [ModalsKeys.DeskingProposalProductsDialog]: <ProposalProductsDialog />,
    [ModalsKeys.DeskingProductCatalogDialog]: <ProductCatalogDialog />,
  };

  return (
    <>
      <FinanceProgramFormContainer />

      {openedModals.map(modalKey => (
        <Fragment key={modalKey}>{modalsWithKeys[modalKey]}</Fragment>
      ))}
    </>
  );
};
