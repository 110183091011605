import { VendorOpportunity } from "@trnsact/trnsact-shared-types";
import {
  FinanceProgramFormValues,
  FinanceQuoteMode,
  MarkupMethods,
  PaymentCalculationMethods,
  ProgramTypes,
  RateTypes,
  SendVia,
  Structures,
} from "../types";

export function convertVOToInitialFormValue(
  voData: VendorOpportunity,
  creditAppData: any,
  equipmentData: any
): Pick<FinanceProgramFormValues, "saleDetails" | "customer" | "equipment"> {
  const customerData = creditAppData.creditApplicationOwner?.[0];

  return {
    customer: {
      title: customerData.title ?? "",
      email: customerData.email ?? "",
      phone: customerData.phone ?? "",
      lastName: customerData.lastName ?? "",
      name: creditAppData.businessName ?? "",
      firstName: customerData.firstName ?? "",
      customerSearch: customerData.ownerPgId,
    },
    equipment: {
      make: equipmentData?.make ?? null,
      year: equipmentData?.year ?? null,
      model: equipmentData?.model ?? null,
      mileage: equipmentData?.mileage ?? null,
      stockId: equipmentData?.stockId ?? null,
      quantity: equipmentData?.quantity ?? null,
      condition: equipmentData?.quantity ?? null,
      vin: equipmentData?.serialNumberVin ?? null,
      totalRetailPrice: equipmentData?.retailPrice ?? null,
      wholesaleCost: equipmentData?.totalWholesaleCost ?? null,
      description: equipmentData?.additionalDescription ?? null,
      totalWholesaleCost: equipmentData?.totalWholesaleCost ?? null,
    },
    saleDetails: {
      financeAmount: 0,
      fees: voData.docFeeAmount ?? 0,
      downPayment: voData.downPayment ?? 0,
      invoiceTotal: voData.invoiceTotal ?? 0,
      salesTax: voData.salesTaxOnInvoice ?? 0,
      equipmentTotal: voData.equipmentCost ? +voData.equipmentCost : 0,
    },
  };
}

export function getDefaultValues(
  voData: VendorOpportunity,
  creditAppData: any,
  equipmentData: any
): FinanceProgramFormValues {
  const initialPartOfValue = convertVOToInitialFormValue(voData, creditAppData, equipmentData);

  return {
    ...initialPartOfValue,
    financeQuote: {
      fee: [],
      menu: "",
      commonRate: "",
      terms: [],
      amount: "",
      program: "",
      residual: "",
      markupRate: "",
      downPayment: "",
      programName: "",
      specifyRates: [],
      dealerMarkup: "",
      advancePayment: "",
      specifyRate: false,
      specifyRatePerTerm: "",
      structure: Structures.None,
      mode: FinanceQuoteMode.Finance,
      programType: ProgramTypes.Loan,
      rateType: RateTypes.InterestRate,
      markupMethod: MarkupMethods.Markup,
      paymentCalculationMethod: PaymentCalculationMethods.Advance,
    },
    sendVia: {
      type: SendVia.Email,
    },
    displaySettings: {
      productPrice: false,
      productDetails: false,
    },
  };
}
