import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { useFormContext } from "react-hook-form";
import { deskingSelectors } from "../../../../model";
import { FormSectionHeader } from "../../../components";
import { FinanceProgramFormValues, FormSectionProps } from "../../../../types";
import { CurrencyInputField, InputField } from "../../../../../../components/form";

export const Equipment = ({ onUpdateCollapse, sectionName }: FormSectionProps) => {
  const { control } = useFormContext<FinanceProgramFormValues>();

  const isSectionOpen = useSelector((state: any) => deskingSelectors.isSectionOpen(state, sectionName));

  return (
    <Box className="section">
      <FormSectionHeader
        name="Equipment"
        sectionName={sectionName}
        isSectionOpen={isSectionOpen}
        onUpdateCollapse={onUpdateCollapse}
      />

      {isSectionOpen && (
        <Box className="sectionFields">
          <Box className="row">
            <InputField label="Quantity" control={control} name="equipment.quantity" />

            <InputField label="Condition" control={control} name="equipment.condition" />

            <InputField label="Year" control={control} name="equipment.year" inputProps={{ type: "number" }} />

            <InputField label="Stock Id" control={control} name="equipment.stockId" />
          </Box>

          <Box className="row">
            <InputField label="Make" control={control} name="equipment.make" />

            <InputField label="Model" control={control} name="equipment.model" />

            <InputField label="Mileage" control={control} name="equipment.mileage" inputProps={{ type: "number" }} />

            <InputField label="Serial/VIN#" control={control} name="equipment.vin" />
          </Box>

          <Box className="row">
            <CurrencyInputField label="Total Wholesale Cost" control={control} name="equipment.totalWholesaleCost" />

            <CurrencyInputField label="Total Retail Price" control={control} name="equipment.totalRetailPrice" />

            <CurrencyInputField label="Wholesale Cost" control={control} name="equipment.wholesaleCost" />

            <InputField label="Additional Description" control={control} name="equipment.description" />
          </Box>
        </Box>
      )}
    </Box>
  );
};
