import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { ProposalMenuOption } from "@trnsact/trnsact-shared-types";
import { MenuOptionCard } from "./MenuOptionCard";
import { MenuCards } from "../../../types";
import { deskingSelectors } from "../../../model";
import { MenuOptionCardsHeader } from "./MenuOptionCardsHeader";
import { useHorizontalScroll } from "../../../../../hooks/useHorizontalScroll";
import { MenuOptionDetail } from "./MenuOptionDetail";

export const MenuOptionCards = ({ isCalculating }: MenuCards) => {
  const classes = useStyles();

  const scrollRef = useHorizontalScroll();

  const menuOption = useSelector(deskingSelectors.selectedMenuOption);
  const groupedMenuOptionsByTerm = useSelector(deskingSelectors.menuByTerms);
  const menuOptions = useSelector(deskingSelectors.menuByTerm) as ProposalMenuOption[];

  return (
    <Box className={classes.cardContainer}>
      <MenuOptionCardsHeader groupedMenuOptionsByTerm={groupedMenuOptionsByTerm} />

      <Box className={classes.cardsContainer} {...({ ref: scrollRef } as any)}>
        {!!menuOptions?.length &&
          menuOptions.map((menuOption, index) => (
            <MenuOptionCard key={index} menuOption={menuOption} isCalculating={isCalculating} />
          ))}
      </Box>

      <MenuOptionDetail menuOption={menuOption} />
    </Box>
  );
};

const useStyles = makeStyles({
  cardContainer: {
    gap: "10px",
    display: "flex",
    flexDirection: "column",
  },
  cardsContainer: {
    gap: "8px",
    display: "flex",
    overflowX: "auto",
    paddingBottom: "6px",
    alignItems: "flex-start",
  },
});
