import { FinanceProgram, ProposalMenuOption } from "@trnsact/trnsact-shared-types";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/src/generated";
import {
  ADD_PRODUCT,
  LAYOUT_SELECT_MENU_OPTION,
  LAYOUT_UPDATE_SECTION_IS_OPEN,
  REMOVE_PRODUCT,
  REMOVE_PRODUCT_ADDON,
  SELECT_PROPOSALS_PRODUCTS,
  SET_EQUIPMENT_DATA,
  SET_FINANCE_PROGRAMS,
  SET_MENU_RESULTS,
  SET_PROPOSAL_CREATED,
  SET_PROPOSAL_MENUS,
  SET_PROPOSALS_PRODUCTS,
  UNSELECT_PROPOSALS_PRODUCTS,
  UPDATE_MENU,
  UPDATE_PRODUCT,
  UPDATE_PROPOSALS_PRODUCTS,
  UPDATE_RECALCULATE,
  UPDATE_TERM,
} from "./actionTypes";
import { DeskingTerm } from "./types";
import { FormSections } from "../types";

const setProposalMenus = (payload: ProposalMenuOption[]) => ({
  type: SET_PROPOSAL_MENUS,
  payload,
});

const setFinancePrograms = (payload: FinanceProgram[]) => ({
  type: SET_FINANCE_PROGRAMS,
  payload,
});

const setEquipmentData = (payload: any) => ({
  type: SET_EQUIPMENT_DATA,
  payload,
});

const updateTerm = (payload: Partial<DeskingTerm>) => ({
  type: UPDATE_TERM,
  payload,
});

const updateProducts = (payload: ProposalProduct[]) => ({
  type: UPDATE_PROPOSALS_PRODUCTS,
  payload,
});

const setProducts = (payload: ProposalProduct[]) => ({
  type: SET_PROPOSALS_PRODUCTS,
  payload,
});

const selectProduct = (payload: ProposalProduct) => ({
  type: SELECT_PROPOSALS_PRODUCTS,
  payload,
});

const unselectProduct = (payload: string) => ({
  type: UNSELECT_PROPOSALS_PRODUCTS,
  payload,
});

const setProposalCreated = (payload: boolean) => ({
  type: SET_PROPOSAL_CREATED,
  payload,
});

const setMenuResults = (payload: any) => ({
  type: SET_MENU_RESULTS,
  payload,
});

const updateMenu = (payload: any) => ({
  type: UPDATE_MENU,
  payload,
});

const addMenuProduct = (payload: { menuType: string; product: ProposalProduct }) => ({
  type: ADD_PRODUCT,
  payload,
});

const removeMenuProduct = (payload: { menuType: string; removeIndex: number }) => ({
  type: REMOVE_PRODUCT,
  payload,
});

const removeMenuProductAddon = (payload: any) => ({
  type: REMOVE_PRODUCT_ADDON,
  payload,
});

const updateMenuProduct = (payload: any) => ({
  type: UPDATE_PRODUCT,
  payload,
});

const updateRecalculate = () => ({
  type: UPDATE_RECALCULATE,
});

const updateSectionIsOpenStatus = (payload: { section: FormSections; isOpen: boolean }) => ({
  type: LAYOUT_UPDATE_SECTION_IS_OPEN,
  payload,
});

const selectMenuOption = (payload: string) => ({
  type: LAYOUT_SELECT_MENU_OPTION,
  payload,
});

export const deskingActions = {
  updateTerm,
  selectProduct,
  unselectProduct,
  setProposalMenus,
  setFinancePrograms,
  setProposalCreated,
  updateMenu,
  setEquipmentData,
  setMenuResults,
  setProducts,
  addMenuProduct,
  removeMenuProduct,
  updateMenuProduct,
  updateRecalculate,
  updateProducts,
  updateSectionIsOpenStatus,
  selectMenuOption,
  removeMenuProductAddon,
};
