import React, { Fragment, ReactNode } from "react";
import { useSelector } from "react-redux";
import { LoadModal } from "./LoadModal";
import { CreateEquipmentModalOld } from "./CreateEquipment/CreateEquipmentModalOld";
import { modalsSelectors } from "../../../redux/modalsReducer";

export enum ModalsKeys {
  Load = "load",
  CreateEquipment = "createEquipment",

  DeskingConfirmSaveDraftDialog = "confirmSaveDraftDialog",
  DeskingProposalProductsDialog = "proposalProductsDialog",
  DeskingProductCatalogDialog = "productCatalogDialog",
}

export const CommonModals = () => {
  const openedModals = useSelector(modalsSelectors.openedModals) as ModalsKeys[];

  const modalsWithKeys: Partial<Record<ModalsKeys, ReactNode>> = {
    [ModalsKeys.Load]: <LoadModal />,
    [ModalsKeys.CreateEquipment]: <CreateEquipmentModalOld />,
  };

  return (
    <>
      {openedModals.map(modalKey => (
        <Fragment key={modalKey}>{modalsWithKeys[modalKey]}</Fragment>
      ))}
    </>
  );
};
