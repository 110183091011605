import React from "react";
import { useQuery } from "react-apollo";
import { useSelector } from "react-redux";
import { Controller, useFormContext } from "react-hook-form";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/src/generated";
import { deskingSelectors } from "../../../../../model";
import { FinanceProgramFormValues } from "../../../../../types";
import { FormInputSelect } from "../../../../../../../components/form";
import { GET_PROPOSAL_MENUS_SELECT_OPTIONS } from "../../../../../api";
import { MenuFields, ProposalProductsCards } from "../../../../components";
import { accountSelectors } from "../../../../../../../redux/accountReducer";
import { MenuSelectsResponse, MenuSelectsVariables } from "../../../../../api/types";

export const Menu = () => {
  const { control } = useFormContext<FinanceProgramFormValues>();

  const accountId = useSelector(accountSelectors.accountId);
  const selectedMenuOption = useSelector(deskingSelectors.selectedMenuOption);
  const selectedProducts = useSelector(deskingSelectors.selectedProposalsProductsArray) as ProposalProduct[];

  const { data, loading: isOptionsLoading } = useQuery<MenuSelectsResponse, MenuSelectsVariables>(
    GET_PROPOSAL_MENUS_SELECT_OPTIONS,
    {
      skip: !accountId,
      variables: { accountId },
    }
  );

  return (
    <>
      <Controller
        control={control}
        name="financeQuote.menu"
        render={({ field }) => (
          <FormInputSelect
            label="Select"
            variant="standard"
            isLoading={isOptionsLoading}
            disabled={!data?.proposalMenus?.length}
            options={data?.proposalMenus ?? []}
            {...field}
          />
        )}
      />

      <MenuFields />

      <ProposalProductsCards
        selectedProducts={selectedProducts}
        selectedMenuOptionProducts={selectedMenuOption?.products ?? []}
      />
    </>
  );
};
