import { FormSections } from "../types";

const financePrograms = (state: any) => state.desking.financePrograms;
const financeProgramsSelectors = (state: any) =>
  Object.values(state.desking.financePrograms).map((program: any) => ({
    label: program.nameInternal,
    value: program.financeProgramId,
  }));
const financeProgramById = (state: any, id: string | undefined) => (id ? state.desking.financePrograms?.[id] : null);
const financeProgramConfigById = (state: any, id: string | undefined) =>
  id ? state.desking.financePrograms?.[id]?.paymentOptionsConfiguration : null;
const proposalMenuById = (state: any, id: string | undefined) => (id ? state.desking.proposalMenus?.[id] : null);
const proposalMenuByCurrentTerm = (state: any) => state.desking.menuBuilder?.[state.desking.term?.term] ?? null;

const term = (state: any) => state.desking.term;

const qualifyResult = (state: any) => state.desking.qualifyProcessResult;

const selectedProposalsProducts = (state: any) => state.desking.proposalProducts.selectedProducts;
const selectedProposalsProductsIds = (state: any) => Object.keys(state.desking.proposalProducts.selectedProducts);
const selectedProposalsProductsArray = (state: any) => Object.values(state.desking.proposalProducts.selectedProducts);
const proposalsProducts = (state: any) => state.desking.proposalProducts.products;

const proposalCreated = (state: any) => state.desking.isProposalCreated;

const menuByTerm = (state: any) => state.desking.menuBuilder?.[state.desking.term?.term]?.menu?.menuOptions ?? [];
const menuByTerms = (state: any) => state.desking.menuBuilder;
const selectedMenuOption = (state: any) => {
  const menuOptions = menuByTerm(state);
  return menuOptions.find((option: any) => option?.name === state.desking.layout.selectedMenuOptions) ?? "";
};

const productConfiguration = (state: any, productId: string) => {
  const menuOption = selectedMenuOption(state);

  const product = menuOption?.products?.find((product: any) => product?.proposalProductId === productId);

  return product?.configuration ?? null;
};

const recalculateStep = (state: any) => state.desking.recalculateStep;

const isSectionOpen = (state: any, section: FormSections) => state.desking.layout.sections?.[section]?.isOpen ?? true;

const equipment = (state: any) => state.desking.equipment;

export const deskingSelectors = {
  term,
  equipment,
  menuByTerm,
  menuByTerms,
  qualifyResult,
  isSectionOpen,
  financePrograms,
  proposalCreated,
  recalculateStep,
  proposalMenuById,
  proposalsProducts,
  financeProgramById,
  selectedMenuOption,
  productConfiguration,
  financeProgramConfigById,
  financeProgramsSelectors,
  selectedProposalsProducts,
  proposalMenuByCurrentTerm,
  selectedProposalsProductsIds,
  selectedProposalsProductsArray,
};
