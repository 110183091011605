import { UseFormSetValue } from "react-hook-form";
import { MarkupType, ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { CommonMenuPriceValues } from "../types";

type Field = "cost" | "markup.markup";

interface Values {
  cost: number;
  markup: number;
  retailCost: number;
  markupType: MarkupType;
}

export function proposalProductUpdateDependentFields(
  field: Field,
  setter: UseFormSetValue<CommonMenuPriceValues>,
  nextValue: number,
  proposalProduct: ProposalProduct,
  values: Values
) {
  setter(field, nextValue);

  const { cost, retailCost, markup, markupType } = values;

  const rules: Record<Field, () => number | null> = {
    cost: () => {
      let retailCostNextValue = 0;

      if (!markup && !proposalProduct.retailCost) {
        retailCostNextValue = nextValue;
      } else if (markup && !retailCost) {
        if (markupType === MarkupType.Percentage) {
          retailCostNextValue = nextValue + nextValue * markup;
        }
        if (markupType === MarkupType.Flat) {
          retailCostNextValue = nextValue + markup;
        }
      } else if (markup && retailCost) {
        if (markupType === MarkupType.Percentage) {
          retailCostNextValue = nextValue + nextValue * markup;
        }
        if (markupType === MarkupType.Flat) {
          retailCostNextValue = nextValue + markup;
        }
      }

      setter("retailCost", retailCostNextValue);

      return retailCostNextValue;
    },
    "markup.markup": () => {
      let retailCostNextValue = 0;

      if (!cost && !retailCost) return null;

      if (cost && !proposalProduct.retailCost) {
        if (markupType === MarkupType.Percentage) {
          retailCostNextValue = cost + cost * nextValue;
        }
        if (markupType === MarkupType.Flat) {
          retailCostNextValue = cost + nextValue;
        }
      } else if (!cost && retailCost) {
        if (markupType === MarkupType.Percentage) {
          retailCostNextValue = retailCost - nextValue;
        }
        if (markupType === MarkupType.Flat) {
          retailCostNextValue = retailCost - nextValue;
        }
      }

      setter("retailCost", retailCostNextValue);

      return retailCostNextValue;
    },
  };

  return rules[field]();
}
