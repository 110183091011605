import React, { useRef } from "react";
import { Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { Control } from "react-hook-form/dist/types/form";
import { TextField, TextFieldProps } from "@material-ui/core";
import { FieldValues } from "react-hook-form/dist/types/fields";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { UseControllerProps } from "react-hook-form/dist/types/controller";

interface PercentageFormatProps {
  name: string;
  inputRef: (instance: NumberFormat<any> | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
}

const PercentageFormat = (props: PercentageFormatProps) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      suffix="%"
      allowNegative
      isNumericString
      decimalScale={2}
      fixedDecimalScale
      getInputRef={inputRef}
      onValueChange={(values: NumberFormatValues) => {
        onChange({ target: { name: props.name, value: values.value } });
      }}
      isAllowed={values => {
        const { floatValue } = values;
        return floatValue === undefined || (floatValue >= -100 && floatValue <= 100);
      }}
    />
  );
};

interface Props<FormValues extends FieldValues = FieldValues> {
  label: string;
  control: Control<FormValues>;
  textFieldProps?: TextFieldProps;
  name: UseControllerProps<FormValues>["name"];
}

export const InterestInputField = <FormValues extends FieldValues = FieldValues>({
  name,
  label,
  control,
  textFieldProps = {},
}: Props<FormValues>) => {
  const classes = useStyles();
  const inputRef = useRef<any>();

  const handleFocus = () => {
    if (inputRef.current) {
      setTimeout(() => {
        inputRef.current.setSelectionRange(0, 0);
      }, 0);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, onChange, ...rest } }) => (
        <TextField
          fullWidth
          size="small"
          label={label}
          defaultValue={0}
          placeholder="0.00%"
          className={classes.input}
          InputProps={{
            inputRef: (instance: NumberFormat<any> | null) => {
              inputRef.current = instance;
              ref(instance);
            },
            inputComponent: PercentageFormat as any,
            onChange: textFieldProps?.onChange ?? onChange,
          }}
          onFocus={handleFocus}
          {...textFieldProps}
          {...rest}
        />
      )}
    />
  );
};

const useStyles = makeStyles({
  startAdornment: {
    margin: "0 0 3px 0",
    "& .MuiSvgIcon-root": {
      fontSize: "1.18rem",
      margin: 0,
    },
  },
  input: {
    "& .MuiInputLabel-asterisk": {
      color: "red",
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});
