import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import { deskingActions, deskingSelectors } from "../../../model";

interface Props {
  groupedMenuOptionsByTerm: Record<string, any>;
}

export const MenuOptionCardsHeader = ({ groupedMenuOptionsByTerm }: Props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const currentTerm = useSelector(deskingSelectors.term);

  const { isBackwardDisabled, isForwardDisabled, forwardTooltip, backwardTooltip } = useMemo(() => {
    const nextVale = Object.keys(groupedMenuOptionsByTerm)[currentTerm.index + 1];
    const backVale = Object.keys(groupedMenuOptionsByTerm)[currentTerm.index - 1];

    return {
      forwardTooltip: nextVale ? `Go to ${nextVale}` : "",
      backwardTooltip: backVale ? `Back to ${backVale}` : "",
      isForwardDisabled: Object.keys(groupedMenuOptionsByTerm).length <= currentTerm.index + 1,
      isBackwardDisabled: currentTerm.index <= 0,
    };
  }, [currentTerm]);

  const handleUpdateTerm = (action: "add" | "sub") => {
    const actionMapper = {
      add: currentTerm.index + 1,
      sub: currentTerm.index - 1,
    };

    dispatch(
      deskingActions.updateTerm({
        index: actionMapper[action],
        term: Object.keys(groupedMenuOptionsByTerm)[actionMapper[action]],
      })
    );
  };

  return (
    <Box className={classes.termPanel}>
      <Typography component="span" variant="subtitle2">
        {currentTerm.term} months
      </Typography>

      <Box className={classes.termPanelActions}>
        <Tooltip title={backwardTooltip}>
          <IconButton size="small" disabled={isBackwardDisabled} onClick={() => handleUpdateTerm("sub")}>
            <ArrowBackIos />
          </IconButton>
        </Tooltip>

        <Tooltip title={forwardTooltip}>
          <IconButton size="small" disabled={isForwardDisabled} onClick={() => handleUpdateTerm("add")}>
            <ArrowForwardIos />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  termPanel: {
    padding: "8px",
    display: "flex",
    borderRadius: "2px",
    alignItems: "center",
    backgroundColor: grey["A100"],
    justifyContent: "space-between",
  },
  termPanelActions: {
    gap: "4px",
    display: "flex",
  },
});
