import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Rate } from "@trnsact/ntp-sdk";
import { useDispatch, useSelector } from "react-redux";
import { Addons } from "./Addons";
import { Fields } from "./Fields";
import { ProposalCardContainer } from "../ProposalCardContainer";
import { deskingActions, deskingSelectors } from "../../../../../model";
import NTP_LOGO from "../../../../../../../assets/img/aftermarket-vendor-logos/ntp-logo.png";
import { CommonMenuPriceValues, MarkupType, ProposalProductCardProps } from "../../../../../types";

export const Card = ({ proposalProduct, type }: ProposalProductCardProps) => {
  const dispatch = useDispatch();

  const updateProductConfiguration = (nextValue: any) => {
    dispatch(
      deskingActions.updateMenuProduct({
        productId: proposalProduct.proposalProductId,
        field: "configuration",
        value: nextValue,
      })
    );
  };

  const proposalProductConfiguration = useSelector((state: any) =>
    deskingSelectors.productConfiguration(state, proposalProduct.proposalProductId)
  ) as Record<"addons" | "coverage" | "deductible", any>;

  const [forcePriceValue, setForcePriceValue] = useState<CommonMenuPriceValues>({
    retailCost: proposalProduct.retailCost,
    cost: proposalProduct.cost,
    markup: proposalProduct.markup,
  });

  const [configuration, setConfiguration] = useState<Record<"addons" | "coverage" | "deductible", any>>({
    addons: [],
    coverage: null,
    deductible: null,
  });

  const data = useMemo<{ API_RESPONSE_DATA: Rate }>(
    () => proposalProduct?.aftermarketProduct?.criteria[0].event.params.AFTERMARKET_PRODUCT_VENDOR_API_DATA,
    []
  );

  useEffect(() => {
    if (type !== "forEdit" || !proposalProductConfiguration) return;

    setConfiguration(proposalProductConfiguration);
  }, [proposalProductConfiguration]);

  const { deductibleOptions, mileageOptions, addonsOptions } = useMemo(() => {
    const accessKey = `${configuration.coverage?.termMonths}-${configuration.coverage?.termOdometer}`;

    return {
      deductibleOptions: _.uniqBy(data.API_RESPONSE_DATA.terms, term => term.deductible.amount).map(
        term => term.deductible.amount
      ),
      mileageOptions: configuration.deductible
        ? data.API_RESPONSE_DATA.terms.filter(term => term.deductible.amount === configuration.deductible)
        : [],
      addonsOptions: data.API_RESPONSE_DATA.terms.reduce<any>((acc, data) => {
        const key = `${data.termMonths}-${data.termOdometer}`;

        acc[key] = data;

        return acc;
      }, {})?.[accessKey],
    };
  }, [data, configuration]);

  const handleDeductibleChange = (selectedAmount: number) => {
    setConfiguration(prev => {
      const nextConfiguration = {
        ...prev,
        coverage: null,
        deductible: selectedAmount,
      };

      if (type === "forEdit") updateProductConfiguration(nextConfiguration);
      return nextConfiguration;
    });
  };

  const handleMileageChange = (selectedTerm: any) => {
    setConfiguration(prev => {
      const nextConfiguration = {
        ...prev,
        coverage: selectedTerm,
      };

      if (type === "forEdit") updateProductConfiguration(nextConfiguration);
      return nextConfiguration;
    });

    setForcePriceValue({
      cost: selectedTerm?.dealerCost ?? 0,
      retailCost: selectedTerm?.dealerCost ?? 0,
      markup: { type: MarkupType.Percentage, markup: 0 },
    });
  };

  const handleCheckboxChange = (lossCode: any, value: boolean) => {
    if (!configuration.coverage) return;

    setConfiguration(prev => {
      const nextAddons = !value
        ? prev.addons.filter((addon: any) => addon.title !== lossCode.title)
        : [...prev.addons, lossCode];

      const nextConfiguration = {
        ...prev,
        addons: nextAddons,
      };

      if (type === "forEdit") updateProductConfiguration(nextConfiguration);
      return nextConfiguration;
    });
  };

  return (
    <ProposalCardContainer
      type={type}
      logo={NTP_LOGO}
      configuration={configuration}
      proposalProduct={proposalProduct}
      forcePriceValue={forcePriceValue}
    >
      <Fields
        configuration={configuration}
        uniqueDeductibles={deductibleOptions}
        filteredMileageOptions={mileageOptions}
        handleMileageChange={handleMileageChange}
        handleDeductibleChange={handleDeductibleChange}
      />

      {addonsOptions && (
        <Addons
          configuration={configuration}
          addonsOptions={addonsOptions}
          handleCheckboxChange={handleCheckboxChange}
        />
      )}
    </ProposalCardContainer>
  );
};
