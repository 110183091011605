import gql from "graphql-tag";

export const GET_PROPOSAL_MENUS = gql`
  query {
    proposalMenus(input: {}) {
      proposalMenuId
      name
      description
      menuOptions {
        name
        ordinal
        description
        proposalMenuOptionId
        titleBgColor
        titleColor
        products {
          cost
          retailCost
          title
          proposalProductId
          thirdPartyUrl
          accountIdCreatedBy
          aftermarketProductToPartnerLinkId
          aftermarketProduct {
            aftermarketProductId
            aftermarketVendorApiChannel
            productName
            productType
            productCategory
            productDescriptionExternal
            criteria
          }
        }
      }
    }
  }
`;
