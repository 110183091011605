import React, { useMemo } from "react";
import clsx from "clsx";
import { useDrop } from "react-dnd";
import { Box, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/src/generated";
import { DnDTypes } from "../../../types";
import { CardPriceBlock } from "../cardPriceBlock";
import { formatCurrency } from "../../../../../utils";
import { ProposalProductCard } from "./ProposalProductCard";
import { deskingActions, deskingSelectors } from "../../../model";

interface Props {
  menuOption: any;
  isCalculating: boolean;
}

export const MenuOptionCard = ({ menuOption, isCalculating }: Props) => {
  const classes = useStyles({
    cardPanelTitleColor: menuOption?.titleColor ?? "",
    cardPanelBg: menuOption?.titleBgColor ?? "",
  });

  const dispatch = useDispatch();
  const selectedMenuOption = useSelector(deskingSelectors.selectedMenuOption);

  const commonPrice = useMemo(
    () =>
      menuOption?.products.reduce(
        (acc: any, { cost, retailCost, markup }: any) => {
          acc.cost += cost;
          acc.markup.markup += markup?.markup ?? 0;
          acc.markup.type = markup?.type;
          acc.retailCost += retailCost;
          return acc;
        },
        { cost: 0, markup: { markup: 0, type: null }, retailCost: 0 }
      ),
    [menuOption?.products]
  );

  const onDropProduct = (product: ProposalProduct) => {
    dispatch(
      deskingActions.addMenuProduct({
        menuType: menuOption.name,
        product,
      })
    );
  };

  const onRemoveProduct = (removeIndex: number) => {
    dispatch(
      deskingActions.removeMenuProduct({
        menuType: menuOption.name,
        removeIndex,
      })
    );

    if (selectedMenuOption.name === menuOption.name && menuOption?.products.length === 1) {
      dispatch(deskingActions.selectMenuOption(menuOption.name));
    }
  };

  const onSelectMenuOption = (optionName: string) => {
    dispatch(deskingActions.selectMenuOption(optionName));
  };

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: DnDTypes.ProposalProduct,
      drop: onDropProduct,
      collect: monitor => ({
        isOver: monitor.isOver(),
      }),
    }),
    []
  );

  return (
    <Box
      className={clsx(classes.card, {
        [classes.overCard]: isOver,
        [classes.selectedCard]: menuOption.name === selectedMenuOption.name,
        [classes.selectedOverCard]: isOver && menuOption.name === selectedMenuOption.name,
      })}
      onClick={() => onSelectMenuOption(menuOption.name)}
    >
      <Box className={classes.cardPanel}>
        <Typography component="span" variant="subtitle2">
          {menuOption.name}
        </Typography>
      </Box>

      <Box className={classes.dnd} {...({ ref: drop } as any)}>
        {!menuOption?.products.length ? (
          <Typography component="span" variant="body1" color="textSecondary">
            Drag & drop options here
          </Typography>
        ) : (
          <Box className={classes.proposalProductCards}>
            {menuOption?.products.length &&
              menuOption?.products.map((product: any, index: number) => (
                <ProposalProductCard
                  key={index}
                  product={product}
                  menuType={menuOption.name}
                  onRemoveProduct={() => onRemoveProduct(index)}
                />
              ))}
          </Box>
        )}
      </Box>

      <CardPriceBlock type="withBg" {...commonPrice} />

      <Box className={classes.cardMonthPrice}>
        <Box className={clsx("monthPrice", { monthPriceCalculating: isCalculating })}>
          <Typography component="span" variant="h6">
            {formatCurrency(menuOption?.payment?.paymentAmountPerPeriod)}
          </Typography>

          <Typography component="span" variant="caption" color="textSecondary">
            per month
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles<Theme, { cardPanelBg: string; cardPanelTitleColor: string }>(({ palette }) => ({
  card: {
    gap: "8px",
    padding: "4px",
    display: "flex",
    cursor: "pointer",
    minHeight: "260px",
    minWidth: "280px",
    flexDirection: "column",
    border: "2px dashed lightgrey",
    justifyContent: "space-between",
  },
  overCard: {
    border: "2px solid lightgrey",
  },
  selectedCard: {
    border: `2px dashed ${palette.primary.main}`,
  },
  selectedOverCard: {
    border: `2px solid ${palette.primary.main}`,
  },
  cardPanel: {
    padding: "12px",
    display: "flex",
    borderRadius: "2px",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: ({ cardPanelBg }) => cardPanelBg,
    color: ({ cardPanelTitleColor }) => cardPanelTitleColor ?? "black",
  },
  dnd: {
    flex: 1,
    display: "flex",
    padding: "4px 0",
    alignItems: "center",
    justifyContent: "center",
  },
  cardMonthPrice: {
    padding: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& .monthPrice": {
      gap: "4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    "& .monthPriceCalculating": {
      filter: "blur(2px)",
    },
  },
  proposalProductCards: {
    gap: "4px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
}));
