import {
  SET_FINANCE_PROGRAMS,
  SET_PROPOSAL_MENUS,
  SELECT_PROPOSALS_PRODUCTS,
  UNSELECT_PROPOSALS_PRODUCTS,
  SET_PROPOSAL_CREATED,
  SET_MENU_RESULTS,
  UPDATE_TERM,
  REMOVE_PRODUCT,
  UPDATE_PRODUCT,
  UPDATE_MENU,
  ADD_PRODUCT,
  UPDATE_RECALCULATE,
  UPDATE_PROPOSALS_PRODUCTS,
  SET_PROPOSALS_PRODUCTS,
  LAYOUT_UPDATE_SECTION_IS_OPEN,
  LAYOUT_SELECT_MENU_OPTION,
  SET_EQUIPMENT_DATA,
  REMOVE_PRODUCT_ADDON,
} from "./actionTypes";
import { DeskingState } from "./types";
import { FormSections } from "../types";

const initialState: DeskingState = {
  recalculateStep: 0,
  isProposalCreated: false,
  financePrograms: {},
  proposalMenus: {},
  term: { index: 0, term: "" },
  proposalProducts: {
    products: [],
    selectedProducts: {},
  },
  menuBuilder: {},
  layout: {
    sections: {},
    selectedMenuOptions: null,
  },
  equipment: null,
};

export const deskingReducer = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case SET_PROPOSALS_PRODUCTS:
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          products: payload,
        },
      };

    case UPDATE_RECALCULATE:
      return {
        ...state,
        recalculateStep: state.recalculateStep + 1,
      };

    case SET_EQUIPMENT_DATA:
      return {
        ...state,
        equipment: payload,
      };

    case SET_FINANCE_PROGRAMS:
      return {
        ...state,
        financePrograms: payload.reduce((acc: any, program: any) => {
          acc[program.financeProgramId] = program;
          return acc;
        }, {}),
      };

    case SET_PROPOSAL_MENUS:
      return {
        ...state,
        proposalMenus: payload.reduce((acc: any, program: any) => {
          acc[program.proposalMenuId] = program;
          return acc;
        }, {}),
      };

    case UPDATE_TERM:
      return {
        ...state,
        term: {
          ...state.term,
          ...payload,
        },
      };

    case UPDATE_PROPOSALS_PRODUCTS:
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          selectedProducts: payload.reduce((acc: any, product: any) => {
            acc[product.proposalProductId] = product;
            return acc;
          }, {}),
        },
      };

    case SELECT_PROPOSALS_PRODUCTS:
      return {
        ...state,
        proposalProducts: {
          selectedProducts: {
            ...state.proposalProducts.selectedProducts,
            [payload.proposalProductId]: payload,
          },
        },
      };

    case UNSELECT_PROPOSALS_PRODUCTS:
      return {
        ...state,
        proposalProducts: {
          ...state.proposalProducts,
          selectedProducts: Object.entries(state.proposalProducts.selectedProducts).reduce<any>((acc, [key, value]) => {
            if (key === payload) return acc;
            acc[key] = value;
            return acc;
          }, {}),
        },
      };

    case SET_PROPOSAL_CREATED:
      return {
        ...state,
        isProposalCreated: payload,
      };

    case SET_MENU_RESULTS:
      return {
        ...state,
        menuBuilder: payload,
        term: {
          index: state.term.index,
          term: state.term.term || Object.keys(payload)[0],
        },
      };

    case UPDATE_MENU:
      return { ...state };

    case ADD_PRODUCT:
      return {
        ...state,
        recalculateStep: state.recalculateStep + 1,
        menuBuilder: Object.entries(state.menuBuilder).reduce<Record<string, any>>((acc, [term, builder]) => {
          acc[term] = {
            ...state.menuBuilder[state.term.term],
            menu: {
              ...builder,
              menuOptions: builder.menu.menuOptions.map((option: any) => {
                if (option.name === payload.menuType) {
                  return {
                    ...option,
                    products: [...option.products, payload.product],
                  };
                }

                return option;
              }),
            },
          };

          return acc;
        }, {}),
      };

    case REMOVE_PRODUCT:
      return {
        ...state,
        recalculateStep: state.recalculateStep + 1,
        menuBuilder: {
          ...state.menuBuilder,
          [state.term.term]: {
            ...state.menuBuilder[state.term.term],
            menu: {
              ...state.menuBuilder[state.term.term].menu,
              menuOptions: state.menuBuilder[state.term.term].menu.menuOptions.map((option: any) => {
                if (option.name === payload.menuType) {
                  return {
                    ...option,
                    products: option.products.filter((product: any, index: number) => index !== payload.removeIndex),
                  };
                }

                return option;
              }),
            },
          },
        },
      };

    case REMOVE_PRODUCT_ADDON:
      return {
        ...state,
        recalculateStep: state.recalculateStep + 1,
        menuBuilder: {
          ...state.menuBuilder,
          [state.term.term]: {
            ...state.menuBuilder[state.term.term],
            menu: {
              ...state.menuBuilder[state.term.term].menu,
              menuOptions: state.menuBuilder[state.term.term].menu.menuOptions.map((option: any) => {
                if (option.name === payload.menuType) {
                  return {
                    ...option,
                    products: option.products.map((product: any) => {
                      if (product.proposalProductId !== payload.productId) return product;

                      return {
                        ...product,
                        addons: product.addons.filter(
                          (addon: any, index: number) => addon.category !== payload.category
                        ),
                      };
                    }),
                  };
                }

                return option;
              }),
            },
          },
        },
      };

    case UPDATE_PRODUCT:
      return {
        ...state,
        recalculateStep: state.recalculateStep + 1,
        menuBuilder: {
          ...state.menuBuilder,
          [state.term.term]: {
            ...state.menuBuilder[state.term.term],
            menu: {
              ...state.menuBuilder[state.term.term].menu,
              menuOptions: state.menuBuilder[state.term.term].menu.menuOptions.map((option: any) => {
                if (option.name === state.layout.selectedMenuOptions) {
                  return {
                    ...option,
                    products: option.products.map((product: any) => {
                      if (product.proposalProductId !== payload.productId) return product;

                      return {
                        ...product,
                        [payload.field]: payload.value,
                      };
                    }),
                  };
                }

                return option;
              }),
            },
          },
        },
      };

    case LAYOUT_UPDATE_SECTION_IS_OPEN:
      return {
        ...state,
        layout: {
          ...state.layout,
          sections: {
            ...state.layout.sections,
            [payload.section]: {
              ...(state.layout.sections?.[payload as FormSections] ?? {}),
              isOpen: payload.isOpen,
            },
          },
        },
      };

    case LAYOUT_SELECT_MENU_OPTION:
      return {
        ...state,
        layout: {
          ...state.layout,
          selectedMenuOptions: state.layout.selectedMenuOptions === payload ? null : payload,
        },
      };

    default:
      return state;
  }
};
