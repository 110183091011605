import gql from "graphql-tag";

export const GET_FINANCE_PROGRAMS_SELECT_OPTIONS = gql`
  query GetFinanceProgramsSelectOptions($accountId: ID!) {
    financePrograms(accountId: $accountId) {
      value: financeProgramId
      label: nameInternal
      validDateEnd
      validDateStart
    }
  }
`;
