import { FinanceProgramFormValues } from "../types";
import { prepareMenuOptionsForAPI } from "./prepareMenuOptionsForAPI";

export function convertProgramToCalculateVariables(
  program: FinanceProgramFormValues["financeQuote"] | undefined,
  proposalMenu: any,
  proposalMenuByCurrentTerm: any | null
) {
  if (!program) return null;

  const products = proposalMenuByCurrentTerm?.menu ?? proposalMenu;

  const prepareMenu: any = {
    name: proposalMenu?.name,
    description: proposalMenu?.description,
    menuOptions: prepareMenuOptionsForAPI(products?.menuOptions),
  };

  return {
    salesTaxPercentage: 0,
    numberOfAdvancePayments: 0,
    rateType: program.rateType,
    amountFinanced: Number(program.amount) || 0,
    financeProgramName: program.programName,
    downPaymentAmount: Number(program.downPayment) || 0,
    residualPercentage: Number(program.residual),
    menu: proposalMenu ? prepareMenu : undefined,
    customerRate: Number(program.commonRate) || null,
    dealerMarkup: Number(program.dealerMarkup) || null,
    paymentPeriodTiming: program.paymentCalculationMethod,
    markups: [{ identifier: "Lender Markup", markupPercentage: 0 }],
    terms: program.terms.map((termValue: any) => ({ term: Number(termValue), rate: +program.commonRate })),
    flatFees: program.fee.map(feeValue => ({ ...feeValue, financed: feeValue.isFinanced, fee: Number(feeValue.fee) })),
  };
}
