import React from "react";
import { Checkbox, FormControl, FormControlProps, InputLabel, ListItemText, MenuItem, Select } from "@material-ui/core";
import { SelectProps } from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { SelectOption } from "../../global";

interface MultiSelectProps extends Omit<SelectProps, "onChange" | "value"> {
  label: string;
  options: SelectOption[];
  value: (string | number)[];
  formControlProps?: FormControlProps;
  onChange: (selected: (string | number)[]) => void;
}

export const MultiSelect: React.FC<MultiSelectProps> = ({
  value,
  label,
  options,
  onChange,
  formControlProps = {},
  ...props
}) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValues = event.target.value as string[];
    onChange(selectedValues);
  };

  const renderSelectedValues = (selected: (string | number)[]) => {
    const selectedLabels = options.filter(option => selected.includes(option.value)).map(option => option.label);
    return selectedLabels.join(", ");
  };

  return (
    <FormControl variant="outlined" size="small" fullWidth className={classes.formControl} {...formControlProps}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={value}
        onChange={handleChange}
        renderValue={selected => renderSelectedValues(selected as string[])}
        MenuProps={{
          getContentAnchorEl: null,
        }}
        {...props}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            <Checkbox checked={value.indexOf(option.value) > -1} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const useStyles = makeStyles({
  formControl: {
    minWidth: 120,
  },
});
