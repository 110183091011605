import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { useFormContext, useWatch } from "react-hook-form";
import { Box, Button, ButtonGroup } from "@material-ui/core";
import { Menu } from "./Menu";
import { Finance } from "./Finance";
import { deskingSelectors } from "../../../../../model";
import { FormSectionHeader } from "../../../../components";
import { FinanceProgramFormValues, FinanceQuoteMode, FormSectionProps } from "../../../../../types";

export const Structure = ({ onUpdateCollapse, sectionName }: FormSectionProps) => {
  const { setValue, control } = useFormContext<FinanceProgramFormValues>();
  const { financeQuote } = useWatch({ control });

  const isSectionOpen = useSelector((state: any) => deskingSelectors.isSectionOpen(state, sectionName));

  const handleChangeMode = (nextValue: FinanceQuoteMode) => {
    setValue("financeQuote.mode", nextValue);
  };

  const renderNestedSectionByMode: Record<FinanceQuoteMode, ReactNode> = {
    [FinanceQuoteMode.Finance]: <Finance />,
    [FinanceQuoteMode.Menu]: <Menu />,
  };

  return (
    <Box className="section">
      <FormSectionHeader
        name="Structure"
        sectionName={sectionName}
        isSectionOpen={isSectionOpen}
        onUpdateCollapse={onUpdateCollapse}
      />

      <Box className="sectionFields">
        <ButtonGroup fullWidth>
          <Button
            color="primary"
            onClick={() => handleChangeMode(FinanceQuoteMode.Finance)}
            variant={financeQuote?.mode === FinanceQuoteMode.Menu ? "outlined" : "contained"}
          >
            Finance
          </Button>

          <Button
            color="primary"
            onClick={() => handleChangeMode(FinanceQuoteMode.Menu)}
            variant={financeQuote?.mode === FinanceQuoteMode.Finance ? "outlined" : "contained"}
          >
            Menu
          </Button>
        </ButtonGroup>

        <Box className="sectionFields">{renderNestedSectionByMode[financeQuote?.mode!]}</Box>
      </Box>
    </Box>
  );
};
