import React, { useEffect, useState } from "react";
import { formatMoney } from "../../utils";
import { format } from "date-fns";

import {
  Grid,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  TableSortLabel,
  makeStyles,
  Tab,
  Tabs,
  Box,
  LinearProgress,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Fuse from "fuse.js";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import _, { after } from "lodash";

import { connect } from "react-redux";
import { AftermarketProductCategory } from "@trnsact/trnsact-shared-types";
import ProposalMenuConstructor from "../ProposalMenuConstructor/ProposalMenuConstructor";
import { adminRoles, portalConfigurationTypes } from "pages/Prequal/constants";

const useStyles = makeStyles(() => ({
  insurance: {
    backgroundColor: "#4E89F3",
    color: "#FFFFFF",
  },
  gap: {
    backgroundColor: "#F564A9",
    color: "#FFFFFF",
  },
  roadside: {
    backgroundColor: "#3CC37A",
    color: "#FFFFFF",
  },
  warranty: {
    backgroundColor: "#FFB64E",
    color: "#FFFFFF",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#f5f5f5",
      cursor: "pointer",
    },
  },
}));

const Q_AFTERMARKET_PRODUCTS = gql`
  query GetAftermarketProducts($accountId: ID!) {
    aftermarketProducts(accountId: $accountId) {
      aftermarketProductId
      productName
      aftermarketVendorApiChannel
      partnerLinks {
        partnerLinkId
        name
      }
      productCategory
      productDescriptionExternal
      criteria
      productDescriptionInternal
      productType
      createdDateTime
    }
  }
`;

const fuseOptions = {
  keys: ["productName", "productCategory", "productType", "productDescriptionExternal", "productCost"],
  threshold: 0.3,
};

const ListAftermarketProducts = ({
  userProfile,
  account,
  portalConfiguration,
}: {
  userProfile: any;
  account: any;
  portalConfiguration: any;
}) => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<any>("productName");
  const [searchText, setSearchText] = useState("");
  const [filteredProducts, setFilteredProducts] = useState<any>([]);
  const [tabValue, setTabValue] = useState(0);

  const { loading, data: { aftermarketProducts } = { aftermarketProducts: [] } } = useQuery(Q_AFTERMARKET_PRODUCTS, {
    variables: { accountId: account.id },
  });

  const [canWrite, setCanWrite] = useState(
    _.get(
      portalConfiguration,
      `${portalConfigurationTypes.aftermarket}.config.rolesSettings.assignedOnly`,
      []
    ).includes(userProfile.vendorContactRole)
  );
  const [canRead, setCanRead] = useState(
    _.get(portalConfiguration, `${portalConfigurationTypes.aftermarket}.config.rolesSettings.readOnly`, []).includes(
      userProfile.vendorContactRole
    )
  );
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  useEffect(() => {
    if (portalConfiguration && userProfile) {
      setIsSuperAdmin([adminRoles.super, adminRoles.singleAccountOnly].includes(userProfile.adminRole));
      setCanWrite(
        _.get(
          portalConfiguration,
          `${portalConfigurationTypes.aftermarket}.config.rolesSettings.assignedOnly`,
          []
        ).includes(userProfile.vendorContactRole)
      );
      setCanRead(
        _.get(
          portalConfiguration,
          `${portalConfigurationTypes.aftermarket}.config.rolesSettings.readOnly`,
          []
        ).includes(userProfile.vendorContactRole)
      );
    }
  }, [portalConfiguration, userProfile]);

  const fuse = new Fuse(aftermarketProducts, fuseOptions);

  const aftermarketProductsWithCost = (aftermarketProducts: any) => {
    return aftermarketProducts.map((product: any) => {
      const costUnformatted = _.get(product, "criteria[0].event.params.DEALERCOST", 0);
      const productCost = costUnformatted ? `$ ${formatMoney(costUnformatted)}` : "N/A";
      return {
        ...product,
        productCost,
      };
    });
  };

  useEffect(() => {
    if (!_.isEmpty(aftermarketProducts)) {
      setFilteredProducts(aftermarketProductsWithCost(aftermarketProducts));
    }
  }, [aftermarketProducts]);

  useEffect(() => {
    const found = searchText
      ? fuse.search(searchText).map(result => result.item)
      : aftermarketProductsWithCost(aftermarketProducts);
    if (found) {
      setFilteredProducts(found);
    }
  }, [searchText]);

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    history.push(`/aftermarket${newValue === 0 ? "" : `/${newValue === 1 ? "menu" : "dashboard"}`}`);
  };

  useEffect(() => {
    switch (location.pathname) {
      case "/aftermarket":
        setTabValue(0);
        break;
      case "/aftermarket/menu":
        setTabValue(1);
        break;
      case "/aftermarket/dashboard":
        setTabValue(2);
        break;
      default:
        setTabValue(0);
        break;
    }
  }, [location.pathname]);

  const handleRequestSort = (property: keyof typeof aftermarketProducts[0]) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    const sortedProducts = _.orderBy(
      filteredProducts,
      [
        product => {
          if (property === "createdDateTime") {
            return new Date(parseInt(product[property]));
          }
          return product[property]?.toString().toLowerCase();
        },
      ],
      [isAsc ? "asc" : "desc"]
    );

    setFilteredProducts(sortedProducts);
  };

  const getChipClass = (type: string) => {
    switch (type) {
      case AftermarketProductCategory.Insurance:
        return classes.insurance;
      case AftermarketProductCategory.Gap:
        return classes.gap;
      case AftermarketProductCategory.Roadside:
        return classes.roadside;
      case AftermarketProductCategory.Warranty:
        return classes.warranty;
      default:
        return "";
    }
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <div>
        <Tabs value={tabValue}>
          <Tab
            label="Products"
            onClick={e => {
              handleChangeTab(e, 0);
            }}
          />
          <Tab
            label="Menu Templates"
            onClick={e => {
              handleChangeTab(e, 1);
            }}
          />
          <Tab
            label="Dashboard"
            onClick={e => {
              handleChangeTab(e, 2);
            }}
          />
        </Tabs>

        <Box p={3} hidden={tabValue !== 0}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container justify="space-between" alignItems="center">
                <Typography variant="h6">LIST</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSuperAdmin === false && canWrite === false}
                  onClick={() => history.push("/aftermarket/add")}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  ADD AFTERMARKET PRODUCT
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search"
                margin="normal"
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === "productName"}
                          direction={orderBy === "productName" ? order : "asc"}
                          onClick={() => handleRequestSort("productName")}
                        >
                          Name
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === "productCategory"}
                          direction={orderBy === "productCategory" ? order : "asc"}
                          onClick={() => handleRequestSort("productCategory")}
                        >
                          Category
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === "productType"}
                          direction={orderBy === "productType" ? order : "asc"}
                          onClick={() => handleRequestSort("productType")}
                        >
                          Type
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === "productDescriptionExternal"}
                          direction={orderBy === "productDescriptionExternal" ? order : "asc"}
                          onClick={() => handleRequestSort("productDescriptionExternal")}
                        >
                          Description
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === "productCost"}
                          direction={orderBy === "productCost" ? order : "asc"}
                          onClick={() => handleRequestSort("productCost")}
                        >
                          Cost
                        </TableSortLabel>
                      </TableCell>
                      <TableCell width={200} align="center">
                        <TableSortLabel
                          active={orderBy === "createdDateTime"}
                          direction={orderBy === "createdDateTime" ? order : "asc"}
                          onClick={() => handleRequestSort("createdDateTime")}
                        >
                          Created Date
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredProducts.map((product: any) => (
                      <TableRow
                        className={classes.tableRow}
                        key={product.aftermarketProductId}
                        onClick={() => history.push(`/aftermarket/edit/${product.aftermarketProductId}`)}
                      >
                        <TableCell>{product.productName}</TableCell>
                        <TableCell>
                          <Chip label={product.productCategory} className={getChipClass(product.productCategory)} />
                        </TableCell>
                        <TableCell>{product.productType}</TableCell>
                        <TableCell>{product.productDescriptionExternal}</TableCell>
                        <TableCell>{product.productCost}</TableCell>
                        <TableCell align="center">
                          {format(new Date(parseInt(product.createdDateTime)), "MM/dd/yyyy HH:ii:ss")}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>

        <Box p={3} hidden={tabValue !== 1}>
          <Typography variant="h6">
            <ProposalMenuConstructor />
          </Typography>
        </Box>
        <Box p={3} hidden={tabValue !== 2}>
          <Typography variant="h6">
            Please contact your TRNSACT Account Manager to set up your aftermarket product dashboard.
          </Typography>
        </Box>
      </div>
    );
  }
};

const mapStateToProps = (state: { userProfile: any; account: any; vp: any; portalConfiguration: any }) => ({
  userProfile: state.userProfile,
  account: state.account,
  vp: state.vp,
  portalConfiguration: state.portalConfiguration,
});
export default connect(mapStateToProps, null)(ListAftermarketProducts);
