import React, { FC, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { useQuery } from "react-apollo";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useFormContext, useWatch } from "react-hook-form";
import { FieldValue } from "react-hook-form/dist/types/fields";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { FinanceProgramFormValues, FormSectionProps, MenuCards } from "../../../../types";
import { convertProgramToCalculateVariables } from "../../../../lib";
import { deskingActions, deskingSelectors } from "../../../../model";
import { FormSectionHeader, MenuOptionCards, PaymentOptionCards } from "../../../components";
import { CALCULATE_MULTIPLE_PAYMENTS, GET_PROPOSAL_MENUS } from "../../../../api";
import {
  CalculatingResponse,
  CalculatingVariables,
  OptionsCardsRenderMode,
  ProposalMenusResponse,
} from "../../../../api/types";

export const MenuBuilder = ({ onUpdateCollapse, sectionName }: FormSectionProps) => {
  const classes = useStyles();

  const [calculateMultiplePayments, setCalculateMultiplePayments] = useState<
    CalculatingResponse["calculateMultiplePayments"]
  >([]);

  const { control, watch } = useFormContext<FinanceProgramFormValues>();
  const { financeQuote } = useWatch({ control });

  const dispatch = useDispatch();
  const menuByTerms = useSelector(deskingSelectors.menuByTerms);
  const recalculateStep = useSelector(deskingSelectors.recalculateStep);
  const proposalMenuByCurrentTerm = useSelector(deskingSelectors.proposalMenuByCurrentTerm);
  const isSectionOpen = useSelector((state: any) => deskingSelectors.isSectionOpen(state, sectionName));
  const proposalMenu = useSelector((state: any) => deskingSelectors.proposalMenuById(state, financeQuote?.menu));

  useQuery<ProposalMenusResponse>(GET_PROPOSAL_MENUS, {
    onCompleted(response) {
      if (!Array.isArray(response?.proposalMenus)) return;
      dispatch(deskingActions.setProposalMenus(response.proposalMenus));
    },
  });

  const { data: calculatingResults, loading: isCalculating, refetch: recalculateMultiplePayments } = useQuery<
    CalculatingResponse,
    CalculatingVariables
  >(CALCULATE_MULTIPLE_PAYMENTS, {
    onCompleted(response) {
      if (!Array.isArray(response?.calculateMultiplePayments)) return;

      const sortedCalculate: CalculatingResponse["calculateMultiplePayments"] = response.calculateMultiplePayments.map(
        ({ paymentOptions, ...rest }) => ({
          ...rest,
          paymentOptions: paymentOptions.sort((a, b) => a.term! - b.term!),
        })
      );

      setCalculateMultiplePayments(sortedCalculate);
    },
    notifyOnNetworkStatusChange: true,
    skip: !financeQuote?.program || !financeQuote?.amount,
    variables: {
      input: convertProgramToCalculateVariables(financeQuote as any, proposalMenu, proposalMenuByCurrentTerm)!,
    },
  });

  useEffect(() => {
    const formRefetchFields: FieldValue<FinanceProgramFormValues["financeQuote"]>[] = [
      "financeQuote.amount",
      "financeQuote.terms",
      "financeQuote.program",
    ];

    const subscription = watch((value, { name }) => {
      if (!formRefetchFields.includes(name)) return;
      dispatch(deskingActions.updateRecalculate());
    });

    return subscription.unsubscribe;
  }, []);

  useEffect(() => {
    recalculateMultiplePayments();
  }, [recalculateStep]);

  const currentRenderMode = useMemo(() => {
    const isMenuExist = !!calculatingResults?.calculateMultiplePayments
      .map(({ paymentOptions }) => !!paymentOptions?.[0]?.menu)
      ?.filter(Boolean)?.length;

    if (isMenuExist) {
      const menuResults = calculatingResults?.calculateMultiplePayments?.[0]?.paymentOptions?.reduce(
        (acc: any, data: any) => {
          if (menuByTerms?.[data.term]) {
            acc[data.term] = {
              ...data,
              menu: {
                ...data.menu,
                menuOptions: data.menu.menuOptions.map((menuOption: any, index: number) => ({
                  ...menuOption,
                  products: menuByTerms[data.term].menu.menuOptions[index].products,
                })),
              },
            };
          } else acc[data.term] = data;

          return acc;
        },
        {}
      );

      dispatch(deskingActions.setMenuResults(menuResults));
    }

    return isMenuExist ? OptionsCardsRenderMode.Menu : OptionsCardsRenderMode.Options;
  }, [calculatingResults]);

  const renderCardsByMode: Record<OptionsCardsRenderMode, FC<MenuCards>> = {
    [OptionsCardsRenderMode.Menu]: MenuOptionCards,
    [OptionsCardsRenderMode.Options]: PaymentOptionCards,
  };

  return (
    <Box className={clsx("section", classes.sectionContainer)}>
      <FormSectionHeader
        name="Menu Builder"
        sectionName={sectionName}
        isSectionOpen={isSectionOpen}
        onUpdateCollapse={onUpdateCollapse}
      />

      {(!financeQuote?.program || !financeQuote?.amount) && (
        <Typography className={classes.fillText} component="span" variant="body2" color="textSecondary">
          Fill in inputs from ‘Finance Quote’ block to see Payment Options
        </Typography>
      )}

      <Box
        className={clsx("sectionFields", {
          [classes.sectionFieldsLoading]: isCalculating && !calculateMultiplePayments.length,
        })}
      >
        {!calculateMultiplePayments.length && isCalculating && (
          <Box className={classes.calculatingBox}>
            <Typography>Calculating...</Typography>

            <CircularProgress color="primary" />
          </Box>
        )}

        {!!calculateMultiplePayments.length &&
          calculateMultiplePayments.map(({ paymentOptions }, index) => {
            const Cards = renderCardsByMode[currentRenderMode];
            return <Cards key={index} paymentOptions={paymentOptions} isCalculating={isCalculating} />;
          })}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  sectionContainer: {
    overflowX: "auto",
  },
  sectionFieldsLoading: {
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  fillText: {
    textAlign: "center",
  },
  calculatingBox: {
    gap: "12px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
});
