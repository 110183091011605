import { SET_CREDIT_APP } from "./types";

const initialState = {};

export const creditAppReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CREDIT_APP:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const contact = (state, ownerId) => state.creditApp.creditApplicationOwner.find(owner => owner.ownerPgId === ownerId);

const contactSelectOptions = state =>
  state.creditApp.creditApplicationOwner.map(owner => ({
    label: `${owner.firstName} ${owner.lastName}`,
    value: owner.ownerPgId,
  }));

const creditAppSelectors = {
  contact,
  contactSelectOptions,
};

export { creditAppSelectors };
