import React from "react";
import { FormInputSelect } from "../../../../../../../components/form";
import { formatCurrency, formatNumberWithDelimiter } from "../../../../../../../utils";

interface Props {
  configuration: Record<"addons" | "coverage" | "deductible", any>;
  uniqueDeductibles: any;
  handleMileageChange: any;
  filteredMileageOptions: any;
  handleDeductibleChange: any;
}

export const Fields = ({
  configuration,
  uniqueDeductibles,
  handleMileageChange,
  filteredMileageOptions,
  handleDeductibleChange,
}: Props) => {
  return (
    <>
      <FormInputSelect
        variant="standard"
        label="Deductible"
        value={configuration.deductible || ""}
        onChange={value => {
          const selectedAmount = parseInt(value, 10);
          handleDeductibleChange(selectedAmount);
        }}
        options={[
          { label: "Select Deductible", value: "" },
          ...uniqueDeductibles.map((amount: any, index: any) => {
            return { label: formatCurrency(amount), value: amount!.toString() };
          }),
        ]}
      />

      {configuration.deductible && (
        <FormInputSelect
          label="Coverage"
          variant="standard"
          value={configuration.coverage ? filteredMileageOptions.indexOf(configuration.coverage) : ""}
          options={[
            { label: "Select Coverage", value: "" },
            ...filteredMileageOptions.map((term: any, index: any) => {
              return {
                label: `${term.termMonths} Months / ${formatNumberWithDelimiter(term.termOdometer)} Miles`,
                value: index.toString(),
              };
            }),
          ]}
          onChange={value => {
            const selectedIndex = parseInt(value, 10);
            const selectedTerm = filteredMileageOptions[selectedIndex];
            handleMileageChange(selectedTerm);
          }}
        />
      )}
    </>
  );
};
