import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { Engine } from "json-rules-engine";
import { FACT_NAMES_BOOLEAN } from "@trnsact/business-criteria/dist/src/models/fact-names/FactNamesBoolean";
import { CustomOperators, FACT_NAMES_DATE, FACT_NAMES_NUMERIC, FACT_NAMES_STRING } from "@trnsact/business-criteria";
import { AftermarketProductQualifiedEventOptions } from "@trnsact/business-criteria/dist/src/models/rule-event/aftermarket/product-qualified/aftermarket-product-qualified-event-options";
import { DynamicFields } from "./DynamicFields";
import { PricingResults } from "./PricingResults";
import { ProposalCardContainer } from "../ProposalCardContainer";
import { CommonMenuPriceValues, FactToCheck, ProposalProductCardProps } from "../../../../../types";

export const Card = ({ proposalProduct, type }: ProposalProductCardProps) => {
  const [
    productConfigurationMatched,
    setProductConfigurationMatched,
  ] = useState<AftermarketProductQualifiedEventOptions | null>(null);

  const [forcePriceValue, setForcePriceValue] = useState<CommonMenuPriceValues>({
    retailCost: proposalProduct.retailCost,
    cost: proposalProduct.cost,
    markup: proposalProduct.markup,
  });

  const newFactsToCheck: FactToCheck[] = [];

  const [factsToCheck, setFactsToCheck] = useState<FactToCheck[]>([]);
  const [facts, setFacts] = useState<Record<string, any>>({});

  const productConfigurationRulesEngine = useRef<Engine>(new Engine());
  productConfigurationRulesEngine.current.addOperator("length", CustomOperators.length);

  useEffect(() => {
    const productRules = _.isArray(proposalProduct?.aftermarketProduct?.criteria)
      ? proposalProduct.aftermarketProduct.criteria
      : null;
    if (productRules) {
      productRules.forEach(aftermarketProductCriteria => {
        aftermarketProductCriteria?.conditions?.formRules?.forEach((rule: any) => {
          if (rule.requiredForQuote) {
            if (
              Object.keys(FACT_NAMES_BOOLEAN).includes(rule.fact) &&
              !_.find(newFactsToCheck, { factKey: rule.fact })
            ) {
              newFactsToCheck.push({ factKey: rule.fact, type: "boolean", rule });
            } else if (
              Object.keys(FACT_NAMES_NUMERIC).includes(rule.fact) &&
              !_.find(newFactsToCheck, { factKey: rule.fact })
            ) {
              newFactsToCheck.push({ factKey: rule.fact, type: "numeric", rule });
            } else if (
              Object.keys(FACT_NAMES_DATE).includes(rule.factName) &&
              !_.find(newFactsToCheck, { factKey: rule.fact })
            ) {
              newFactsToCheck.push({ factKey: rule.fact, type: "numeric", rule });
            } else if (Object.keys(FACT_NAMES_STRING).includes(rule.fact)) {
              if (_.find(newFactsToCheck, { factKey: rule.fact })) {
                if (rule.exactMatch) {
                  const factToCheckIndex = newFactsToCheck.findIndex(fact => fact.factKey === rule.fact);
                  newFactsToCheck[factToCheckIndex].options = _.uniq(
                    newFactsToCheck[factToCheckIndex].options?.concat(
                      _.isArray(rule.exactMatch) ? rule.exactMatch : [rule.exactMatch]
                    )
                  );
                }
              } else {
                newFactsToCheck.push({
                  factKey: rule.fact,
                  type: "string",
                  options: rule.exactMatch
                    ? Array.isArray(rule.exactMatch)
                      ? rule.exactMatch
                      : [rule.exactMatch]
                    : null,
                  rule,
                });
              }
            }
          }
        });
        productConfigurationRulesEngine.current.addRule({
          ...aftermarketProductCriteria,
          conditions: { all: aftermarketProductCriteria.conditions.jsonRules.conditions.all },
        });

        // A configuration could have an empty array of conditions, if this is the case then we assume
        // the rule automatically passes
        if (aftermarketProductCriteria.conditions.jsonRules.conditions.all.length === 0) {
          setProductConfigurationMatched(aftermarketProductCriteria.event);
          setForcePriceValue(prev => ({
            cost: aftermarketProductCriteria.event?.params?.DEALER_COST ?? 0,
            markup: { ...prev.markup, markup: aftermarketProductCriteria.event?.params?.MARKUP?.AMOUNT ?? 0 },
            retailCost: aftermarketProductCriteria.event?.params?.SUGGESTED_RETAIL_PRICE ?? 0,
          }));
        }

        if (newFactsToCheck.length > 0) {
          setFactsToCheck(newFactsToCheck);
        }
      });
    }
  }, [proposalProduct]);

  useEffect(() => {
    if (facts) {
      if (
        Object.keys(facts).length === 0 ||
        _.some(facts, (value, key) => {
          return (
            (facts[key] === null || facts[key] === undefined) &&
            _.find(factsToCheck, f => f.factKey === key)?.rule.runEngineOnNull !== true
          );
        })
      ) {
        console.log("No facts to check");
        return;
      }

      productConfigurationRulesEngine.current
        .run()
        .then(engineResults => {
          // console.log(`engineResults: ${JSON.stringify(engineResults)}`);

          if (engineResults.events.length === 0) {
            // When events.length is 0, it means that the rules engine did not find any rules that matched the facts
            setProductConfigurationMatched(null);
            return;
          }

          engineResults.events.map(event => {
            if (event.type === "AFTERMARKET_PRODUCT_GET_DYNAMIC_PRODUCTS") {
              // Test VIN: 3AKJHHDR4LSLL3208
              productConfigurationRulesEngine.current.removeRule("NTP Product Dynamic Coverage");
              /*
              TODO: Determine when to handle this call for NTP products
              getAftermarketProductsByVendorApiChannel({
                variables: {
                  // aftermarketVendorApiChannel: 'NTP',
                  input: {
                    aftermarketVendorApiChannel: "NTP",
                    data: {
                      odometer: productConfigurationRulesEngine.current.getFact(FACT_NAMES_NUMERIC.ODOMETER_MILEAGE).value,
                      vin: productConfigurationRulesEngine.current.getFact(FACT_NAMES_STRING.VIN).value,
                    },
                  },
                },
              });
              */
            } else if (event.type === "OVERRIDE_STRING_FACT_OPTIONS") {
              try {
                const overrides = event.params?.overrides;
                if (overrides) {
                  overrides.forEach((override: any) => {
                    const factName = override.fact;
                    const factToCheckIndex = factsToCheck.findIndex(fact => fact.factKey === factName);
                    const factToCheck = factsToCheck[factToCheckIndex];
                    if (factToCheck?.type === "string") {
                      factsToCheck[factToCheckIndex].options = override.values;
                      setFactsToCheck([...factsToCheck]);
                    }
                  });
                }
              } catch (e) {
                console.log(`Error during OVERRIDE_STRING_FACT_OPTIONS: ${(e as any).message}`);
              }
            } else if (event.type === "AFTERMARKET_PRODUCT_QUALIFIED") {
              const typedEvent: AftermarketProductQualifiedEventOptions = event as AftermarketProductQualifiedEventOptions;
              // setDraggableAftermarketProducts(event.params.AFTERMARKET_PRODUCT_VENDOR_API_DATA);
              // TODO: use event.params.AFTERMARKET_PRODUCT_VENDOR_API_DATA to set cost / markup and other data
              setProductConfigurationMatched(typedEvent);

              setForcePriceValue(prev => ({
                cost: typedEvent?.params?.DEALER_COST ?? 0,
                markup: { ...prev.markup, markup: typedEvent?.params?.MARKUP?.AMOUNT ?? 0 },
                retailCost: typedEvent?.params?.SUGGESTED_RETAIL_PRICE ?? 0,
              }));
            }
          });
        })
        .catch(console.log);
    }
  }, [facts]);

  return (
    <ProposalCardContainer type={type} proposalProduct={proposalProduct} forcePriceValue={forcePriceValue}>
      <PricingResults isMatched={!!productConfigurationMatched} factsToCheck={factsToCheck} />

      <DynamicFields
        facts={facts}
        setFacts={setFacts}
        factsToCheck={factsToCheck}
        productConfigurationRulesEngine={productConfigurationRulesEngine}
      />
    </ProposalCardContainer>
  );
};
