import { gql } from "apollo-boost";

export const GET_PROPOSALS_TABLE_DATA = gql`
  query($voId: ID) {
    proposals(voId: $voId) {
      id
      amountRequested
      creditProductIds
      riskTier
      vendorOpportunityId
      accountId
      vendorSalespersonId
      firstName
      lastName
      email
      businessName
      status
      createdDateTime
      salesPersonName
      fullName
      paymentId
      creditSubmissionDynamicsId
      lenderPaymentOptions {
        lenderId
        lenderType
        payment
        paymentId
        proposalId
        residual
        residualMultiplier
        term
        useMaxApprovedAmount
      }
    }
  }
`;
