import React from "react";
import { Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { Control } from "react-hook-form/dist/types/form";
import { TextField, TextFieldProps } from "@material-ui/core";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { UseControllerProps } from "react-hook-form/dist/types/controller";
import { formatCurrency, parseCurrency } from "../../../utils";

interface Props<FormValues extends FieldValues = FieldValues> {
  label: string;
  control: Control<FormValues>;
  name: UseControllerProps<FormValues>["name"];
  textFieldProps?: TextFieldProps;
}

export const CurrencyInputField = <FormValues extends FieldValues = FieldValues>({
  name,
  label,
  control,
  textFieldProps = {},
}: Props<FormValues>) => {
  const classes = useStyles();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref, onChange, value, ...rest } }) => (
        <TextField
          ref={ref}
          fullWidth
          size="small"
          label={label}
          className={classes.input}
          value={formatCurrency(value) === "" ? "$0" : formatCurrency(value)}
          onChange={e => {
            onChange({
              ...e,
              target: {
                ...e.target,
                value: parseCurrency(e.target.value.replace(/[^.0-9]/g, "")),
              },
            });
          }}
          {...textFieldProps}
          {...rest}
        />
      )}
    />
  );
};

const useStyles = makeStyles({
  startAdornment: {
    margin: "0 0 3px 0",

    "& .MuiSvgIcon-root": {
      fontSize: "1.18rem",
      margin: 0,
    },
  },
  input: {
    "& .MuiInputLabel-asterisk": {
      color: "red",
    },

    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});
