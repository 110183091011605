const SET_FINANCE_PROGRAMS = "DESKING_SET_FINANCE_PROGRAMS";

const SET_PROPOSAL_MENUS = "DESKING_SET_PROPOSAL_MENUS";

const UPDATE_TERM = "DESKING_UPDATE_TERM";

const SELECT_PROPOSALS_PRODUCTS = "DESKING_SELECT_PROPOSALS_PRODUCTS";
const UNSELECT_PROPOSALS_PRODUCTS = "DESKING_UNSELECT_PROPOSALS_PRODUCTS";
const UPDATE_PROPOSALS_PRODUCTS = "DESKING_UPDATE_PROPOSALS_PRODUCTS";
const SET_PROPOSALS_PRODUCTS = "DESKING_SET_PROPOSALS_PRODUCTS";

const SET_PROPOSAL_CREATED = "DESKING_SET_PROPOSAL_CREATED";

const UPDATE_RECALCULATE = "DESKING_UPDATE_RECALCULATE";

const SET_MENU_RESULTS = "DESKING_SET_MENU_RESULTS";
const UPDATE_MENU = "DESKING_UPDATE_MENU";
const ADD_PRODUCT = "DESKING_ADD_PRODUCT";
const REMOVE_PRODUCT = "DESKING_REMOVE_PRODUCT";
const REMOVE_PRODUCT_ADDON = "DESKING_UPDATE_PRODUCT_ADDON";
const UPDATE_PRODUCT = "DESKING_UPDATE_PRODUCT";

const LAYOUT_UPDATE_SECTION_IS_OPEN = "DESKING_LAYOUT_UPDATE_SECTION_IS_OPEN";
const LAYOUT_SELECT_MENU_OPTION = "DESKING_LAYOUT_SELECT_MENU_OPTION";

const SET_EQUIPMENT_DATA = "DESKING_SET_EQUIPMENT_DATA";

export {
  UPDATE_TERM,
  SET_PROPOSAL_MENUS,
  SET_PROPOSAL_CREATED,
  SET_FINANCE_PROGRAMS,
  SELECT_PROPOSALS_PRODUCTS,
  UNSELECT_PROPOSALS_PRODUCTS,
  ADD_PRODUCT,
  UPDATE_MENU,
  REMOVE_PRODUCT,
  UPDATE_PRODUCT,
  SET_MENU_RESULTS,
  UPDATE_RECALCULATE,
  UPDATE_PROPOSALS_PRODUCTS,
  SET_PROPOSALS_PRODUCTS,
  LAYOUT_UPDATE_SECTION_IS_OPEN,
  LAYOUT_SELECT_MENU_OPTION,
  SET_EQUIPMENT_DATA,
  REMOVE_PRODUCT_ADDON,
};
