import React from "react";
import Search from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import { Box, InputAdornment } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import { ProductsFilters } from "../../../types";
import { proposalProductsSortByOptions } from "../../../constants";
import { FormRadioGroup, InputField } from "../../../../../components/form";

export const ProposalProductsFilters = () => {
  const classes = useStyles();

  const { control } = useFormContext<ProductsFilters>();

  return (
    <Box className={classes.filters}>
      <InputField
        name="search"
        label="Search"
        control={control}
        inputProps={{
          variant: "outlined",
          placeholder: "Enter value...",
          InputProps: {
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          },
        }}
      />

      <Controller
        name="sortBy"
        control={control}
        render={({ field }) => (
          <FormRadioGroup row label="Sort by" options={proposalProductsSortByOptions} {...field} />
        )}
      />
    </Box>
  );
};

const useStyles = makeStyles({
  filters: {
    gap: "16px",
    display: "flex",
    flexDirection: "column",
  },
});
