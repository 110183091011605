import React, { useMemo } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import ProposalProductsCards from "./ProposalProductsCards";

interface Props {
  selectedProducts: ProposalProduct[];
  selectedMenuOptionProducts: ProposalProduct[];
}

export const ProposalProductsCardsFactory = ({ selectedProducts, selectedMenuOptionProducts }: Props) => {
  const classes = useStyles();

  const renderCards = useMemo(() => {
    const productsForEdit = selectedMenuOptionProducts.map(product => (
      <ProposalProductsCards key={`forEdit-${product.proposalProductId}`} product={product} type="forEdit" />
    ));

    const products = selectedProducts.map(product => (
      <ProposalProductsCards key={`simple-${product.proposalProductId}`} product={product} type="simple" />
    ));

    return [...productsForEdit, ...products];
  }, [selectedProducts]);

  return <Box className={classes.container}>{renderCards}</Box>;
};

const useStyles = makeStyles({
  container: {
    gap: "8px",
    display: "flex",
    maxHeight: "800px",
    overflowY: "auto",
    flexDirection: "column",
  },
});
