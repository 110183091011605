import { AftermarketProductTypes } from "../types";

export function proposalProductsFilter(product: any, filters: any) {
  const searchResult = product.title?.toLowerCase()?.includes(filters?.search?.toLowerCase());

  const sortResult =
    filters?.sortBy === AftermarketProductTypes.Simple
      ? !product.aftermarketProduct?.aftermarketVendorApiChannel
      : product.aftermarketProduct?.aftermarketVendorApiChannel === filters?.sortBy;

  return searchResult && (filters?.sortBy === "all" || sortResult);
}
