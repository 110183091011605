import { MarkupTyped } from "@trnsact/trnsact-shared-types/src/generated";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { CalculateMultiplePaymentsPaymentOptionsResponse } from "@trnsact/trnsact-shared-types";

export enum SendVia {
  Email = "Email",
  Text = "Text",
  TextAndEmail = "TextAndEmail",
}

export enum FinanceQuoteMode {
  Finance = "Finance",
  Menu = "Menu",
}

export enum RateTypes {
  InterestRate = "INTEREST_RATE",
  RateFactor = "RATE_FACTOR",
}

export enum Structures {
  None = "none",
  Markup = "financeProfit",
  Subsidy = "subsidy",
}

export enum MarkupMethods {
  Markup = "markup",
  CustomerRate = "customerRate",
}

export enum ProgramTypes {
  Loan = "loan",
  Lease = "lease",
  EFA = "EFA",
}

export enum PaymentCalculationMethods {
  Advance = "BEGINNING_OF_PERIOD",
  Arrears = "END_OF_PERIOD",
}

interface CustomerForm {
  customerSearch: string;
  name: string;
  title: string;
  email: string;
  lastName: string;
  firstName: string;
  phone: string;
}

export interface MenuCards {
  paymentOptions: CalculateMultiplePaymentsPaymentOptionsResponse[];
  isCalculating: boolean;
}

interface SaleDetailsForm {
  fees: number;
  salesTax: number;
  downPayment: number;
  invoiceTotal: number;
  financeAmount: number;
  equipmentTotal: number;
}

interface EquipmentForm {
  vin: string;
  year: string;
  make: string;
  model: string;
  mileage: string;
  stockId: string;
  quantity: string;
  condition: string;
  description: string;
  wholesaleCost: string;
  totalRetailPrice: string;
  totalWholesaleCost: string;
}

interface Fee {
  fee: string;
  identifier: string;
  isFinanced: boolean;
}

interface SpecifyRates {
  term: string;
  rate: string;
}

interface FinanceQuoteForm {
  mode: FinanceQuoteMode;
  commonRate: string;
  amount: string;
  terms: string[];
  program: string;
  residual: string;
  rateType: string;
  structure: string;
  downPayment: string;
  programType: string;
  programName: string;
  dealerMarkup: string;
  specifyRate: boolean;
  advancePayment: string;
  specifyRatePerTerm: string;

  markupRate: string;
  markupMethod: string;
  paymentCalculationMethod: string;

  specifyRates: SpecifyRates[];
  fee: Fee[];

  menu: string;
}

interface SendViaForm {
  type: SendVia;
}

interface ProposalSettings {
  productPrice: boolean;
  productDetails: boolean;
}

export interface FinanceProgramFormValues {
  customer: CustomerForm;
  saleDetails: SaleDetailsForm;
  equipment: EquipmentForm;
  financeQuote: FinanceQuoteForm;
  sendVia: SendViaForm;
  displaySettings: ProposalSettings;
}

export interface FactToCheck {
  rule: any;
  factKey: string;
  options?: string[];
  type: "boolean" | "numeric" | "string";
}

export interface CommonMenuPriceValues {
  cost: number;
  retailCost: number;
  markup: MarkupTyped | null | undefined;
}

export interface SubmitOptions {
  isDraft: boolean;
}

export enum DnDTypes {
  ProposalProduct = "proposalProduct",
}

export enum AftermarketProductTypes {
  Simple = "Simple",
  Gaig = "GAIG",
  Ntp = "NTP",
}

export interface ProposalProductCardProps {
  type: "simple" | "forEdit";
  proposalProduct: ProposalProduct;
}

export interface FormSectionProps {
  sectionName: FormSections;
  onUpdateCollapse?: (section: FormSections, nextValue: boolean) => void;
}

export enum FormSections {
  Customer = "customer",
  SaleDetails = "saleDetails",
  Equipment = "equipment",
  Structure = "structure",
  MenuBuilder = "menuBuilder",
  SendVia = "sendVia",
}

export enum MarkupType {
  Flat = "FLAT",
  Percentage = "PERCENTAGE",
}

export interface ProductsFilters {
  search: string;
  sortBy: AftermarketProductTypes | "all";
}
