import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { EmptyList } from "./EmptyList";
import { ProductLogo } from "./ProductLogo";
import { ProductsFilters } from "../../../types";
import { ProposalProductsFilters } from "./Filters";
import { proposalProductsFilter } from "../../../lib";
import { useModal } from "../../../../../hooks/useModal";
import { FormCheckbox } from "../../../../../components/form";
import { deskingActions, deskingSelectors } from "../../../model";
import { ModalsKeys } from "../../../../../components/shared/Modals";
import { ProductCatalogDetails } from "../ProductCatalog/ProductCatalogDetails";

export const ProposalProductsDialog = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const selectedProposalsProductsIds = useSelector(deskingSelectors.selectedProposalsProductsIds);

  const { isOpen, data, handleClose } = useModal(ModalsKeys.DeskingProposalProductsDialog);

  const form = useForm<ProductsFilters>({
    defaultValues: { search: "", sortBy: "all" },
  });

  const formWatchedValue = useWatch({ control: form.control });

  const { proposalProducts, isProductsExist } = useMemo(() => {
    const filteredProducts = data?.products?.filter((product: any) =>
      proposalProductsFilter(product, formWatchedValue)
    ) as ProposalProduct[];

    return {
      proposalProducts: filteredProducts,
      isProductsExist: !!filteredProducts?.length,
    };
  }, [formWatchedValue]);

  const [selectedProductsIds, setSelectedProductsIds] = useState<string[]>([]);

  const handelConfirm = () => {
    const products = proposalProducts.filter(({ proposalProductId }) =>
      selectedProductsIds.includes(proposalProductId)
    );

    dispatch(deskingActions.updateProducts(products));
    handleClose();
  };

  useEffect(() => {
    setSelectedProductsIds(selectedProposalsProductsIds);

    return () => setSelectedProductsIds([]);
  }, [selectedProposalsProductsIds]);

  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth onClose={handleClose}>
      <DialogTitle>
        <Box className={classes.dialogTitle}>
          <Typography component="span" variant="h6">
            Product
          </Typography>

          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent dividers>
        <Box className={classes.dialogContent}>
          <FormProvider {...form}>
            <ProposalProductsFilters />
          </FormProvider>

          <Typography component="span" variant="subtitle2">
            Products
          </Typography>

          <Box className="products">
            {isProductsExist ? (
              proposalProducts.map(product => (
                <Box key={product.proposalProductId} className="product">
                  <Box>
                    <ProductLogo product={product} />
                  </Box>

                  <FormCheckbox
                    label={product.title}
                    checked={selectedProductsIds.includes(product.proposalProductId)}
                    onChange={() => {
                      setSelectedProductsIds(prev => {
                        if (!selectedProductsIds.includes(product.proposalProductId)) {
                          return [...prev, product.proposalProductId];
                        }

                        return prev.filter(id => id !== product.proposalProductId);
                      });
                    }}
                  />

                  <Tooltip
                    title={
                      <div
                        style={{
                          backgroundColor: "white",
                          color: "#000",
                          padding: "10px",
                          width: "300px",
                          borderRadius: "8px",
                          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <ProductCatalogDetails proposalProduct={product} key={product.proposalProductId} />
                      </div>
                    }
                    PopperProps={{
                      modifiers: {
                        name: "preventOverflow",
                        enabled: true,
                      },
                    }}
                    className={classes.infoIcon}
                  >
                    <InfoIcon color="primary" />
                  </Tooltip>
                </Box>
              ))
            ) : (
              <EmptyList />
            )}
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button size="small" color="primary" variant="contained" onClick={handelConfirm}>
          Confirm
        </Button>

        <Button size="small" color="primary" variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  dialogTitle: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  dialogContent: {
    gap: "10px",
    display: "flex",
    flexDirection: "column",

    "& .products": {
      gap: "8px",
      height: "300px",
      display: "flex",
      overflow: "auto",
      flexDirection: "column",

      "& .product": {
        gap: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      },
    },
  },
  formCheckbox: {
    flexGrow: 1,
    textAlign: "left",
  },
  infoIcon: {
    marginLeft: "auto",
  },
});
