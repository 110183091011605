import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Chip, Typography } from "@material-ui/core";
import { useModal } from "../../../../../hooks/useModal";
import { deskingActions, deskingSelectors } from "../../../model";
import { ModalsKeys } from "../../../../../components/shared/Modals";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/src/generated";

export const MenuFields = () => {
  const classes = useStyles();

  const { handleOpen: onOpenProposalProductsDialog } = useModal(ModalsKeys.DeskingProposalProductsDialog);

  const dispatch = useDispatch();
  const selectedProducts = useSelector(deskingSelectors.selectedProposalsProductsArray) as ProposalProduct[];
  const products = useSelector(deskingSelectors.proposalsProducts) as ProposalProduct[];

  const handleUnselectProduct = (id: string) => {
    dispatch(deskingActions.unselectProduct(id));
  };

  return (
    <Box className={classes.container}>
      <Box className="field">
        <Typography variant="body2" color="textSecondary">
          Product
        </Typography>

        <Box className="selectedValues">
          {!!selectedProducts?.length &&
            selectedProducts.map(product => (
              <Chip
                key={product.title}
                size="small"
                label={product.title}
                onDelete={() => {
                  handleUnselectProduct(product.proposalProductId);
                }}
              />
            ))}
        </Box>

        <Box>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              onOpenProposalProductsDialog({ products });
            }}
          >
            {!!selectedProducts?.length ? "Select more" : "Select"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "4px",
    display: "flex",
    flexDirection: "column",

    "& .field": {
      gap: "4px",
      display: "flex",
      flexDirection: "column",

      "& .selectedValues": {
        gap: "4px",
        display: "flex",
        flexWrap: "wrap",
      },
    },
  },
});
