import React from "react";
import { Modal } from "@material-ui/core";
import { ModalsKeys } from "../Modals";
import { useModal } from "../../../../hooks/useModal";
import EquipmentInputModal from "../../../EquipmentInputModal";

export const CreateEquipmentModalOld = () => {
  const { isOpen, handleClose, data } = useModal(ModalsKeys.CreateEquipment);

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <EquipmentInputModal
        vo={data?.vo}
        account={data?.account}
        handleClose={handleClose}
        creditApp={data?.creditApp}
        vendorProfile={data?.vendorProfile}
        equipmentData={data?.equipmentData}
        equipmentId={data?.equipmentData?.equipments?.[0].equipmentId}
      />
    </Modal>
  );
};
