import React from "react";
import { DndProvider } from "react-dnd";
import { Box } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  EquipmentSection,
  CustomerSection,
  MenuBuilderSection,
  StructureSection,
  SaleDetailsSection,
  SendViaSection,
} from "./sections";
import { FormSections } from "../../../types";
import { deskingActions } from "../../../model";

export const FinanceProgramForm = () => {
  const dispatch = useDispatch();

  const handleUpdateCollapse = (section: FormSections, nextValue: boolean) => {
    dispatch(deskingActions.updateSectionIsOpenStatus({ section, isOpen: nextValue }));
  };

  return (
    <>
      <Box className="row">
        <CustomerSection sectionName={FormSections.Customer} />

        <SaleDetailsSection sectionName={FormSections.SaleDetails} />
      </Box>

      <EquipmentSection sectionName={FormSections.Equipment} onUpdateCollapse={handleUpdateCollapse} />

      <Box className="row">
        <DndProvider backend={HTML5Backend}>
          <StructureSection sectionName={FormSections.Structure} />

          <MenuBuilderSection sectionName={FormSections.MenuBuilder} />
        </DndProvider>
      </Box>

      <SendViaSection sectionName={FormSections.SendVia} />
    </>
  );
};
