import {
  SendVia,
  RateTypes,
  Structures,
  ProgramTypes,
  PaymentCalculationMethods,
  MarkupMethods,
  MarkupType,
  AftermarketProductTypes,
} from "../types";
import { ProposalProductsFilters } from "../ui/dialogs/ProposalProducts/Filters";

const sendViaOptions = [
  { label: "Email", value: SendVia.Email },
  { label: "Text", value: SendVia.Text },
  { label: "Text and email", value: SendVia.TextAndEmail },
];

const rateTypeOptions = [
  { label: "Interest Rate", value: RateTypes.InterestRate },
  { label: "Rate Factor", value: RateTypes.RateFactor },
];

const structureOptions = [
  { label: "None", value: Structures.None },
  { label: "Markup", value: Structures.Markup },
  { label: "Subsidy", value: Structures.Subsidy },
];

const markupOptions = [
  { label: "Markup %", value: MarkupMethods.Markup },
  { label: "Customer Rate", value: MarkupMethods.CustomerRate },
];

const programTypeOptions = [
  { label: "Loan", value: ProgramTypes.Loan },
  { label: "Lease", value: ProgramTypes.Lease },
  { label: "EFA", value: ProgramTypes.EFA },
];

const paymentCalculationMethodOptions = [
  { label: "Advance", value: PaymentCalculationMethods.Advance },
  { label: "Arrears", value: PaymentCalculationMethods.Arrears },
];

const termsOptions = [
  { label: "12", value: "12" },
  { label: "24", value: "24" },
  { label: "36", value: "36" },
  { label: "48", value: "48" },
  { label: "60", value: "60" },
  { label: "72", value: "72" },
  { label: "84", value: "84" },
  { label: "96", value: "96" },
  { label: "108", value: "108" },
  { label: "120", value: "120" },
];

const markupProposalOptions = [
  { label: "$", value: MarkupType.Flat },
  { label: "%", value: MarkupType.Percentage },
];

const proposalProductsSortByOptions = [
  { label: "All products", value: "all" },
  { label: "Simple", value: AftermarketProductTypes.Simple },
  { label: "NTP", value: AftermarketProductTypes.Ntp },
  { label: "GAIG", value: AftermarketProductTypes.Gaig },
];

export {
  termsOptions,
  markupOptions,
  sendViaOptions,
  rateTypeOptions,
  structureOptions,
  programTypeOptions,
  markupProposalOptions,
  proposalProductsSortByOptions,
  paymentCalculationMethodOptions,
};
