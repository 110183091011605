import React from "react";
import { Box } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { blue } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { logError } from "../../../../../utils/logger";
import { FinanceProgramForm } from "./FinanceProgramForm";
import { FinanceProgramFormActions } from "./FinanceProgramFormActions";
import { FinanceProgramFormValues, SubmitOptions } from "../../../types";
import { accountSelectors } from "../../../../../redux/accountReducer";
import { collectDataForCreateProposal, getDefaultValues } from "../../../lib";
import { CREATE_PROPOSAL_V2, GET_EQUIPMENT, GET_FINANCE_PROGRAMS, GET_PROPOSAL_PRODUCTS } from "../../../api";
import {
  CreateProposalResponse,
  CreateProposalVariables,
  EquipmentsResponse,
  EquipmentsVariables,
  FinanceProgramsResponse,
  FinanceProgramsVariables,
  ProposalProductsResponse,
} from "../../../api/types";
import { useModal } from "../../../../../hooks/useModal";
import { deskingActions, deskingSelectors } from "../../../model";
import { ModalsKeys } from "../../../../../components/shared/Modals";

export const FinanceProgramFormContainer = () => {
  const classes = useStyles();

  const { handleOpen } = useModal(ModalsKeys.DeskingConfirmSaveDraftDialog);

  const dispatch = useDispatch();
  const vo = useSelector((state: any) => state.vo);
  const accountId = useSelector(accountSelectors.accountId);
  const menuByTerms = useSelector(deskingSelectors.menuByTerms);
  const creditApplication = useSelector((state: any) => state.creditApp);

  const { data: equipmentData } = useQuery<EquipmentsResponse, Partial<EquipmentsVariables>>(GET_EQUIPMENT, {
    skip: !vo.vendorOpportunityId,
    variables: { VOId: vo.vendorOpportunityId },
    onCompleted(response) {
      if (!Array.isArray(response?.equipments)) return;
      dispatch(deskingActions.setEquipmentData(response));
    },
  });

  useQuery<FinanceProgramsResponse, FinanceProgramsVariables>(GET_FINANCE_PROGRAMS, {
    skip: !accountId,
    variables: { accountId },
    onCompleted(response) {
      if (!Array.isArray(response?.financePrograms)) return;
      dispatch(deskingActions.setFinancePrograms(response.financePrograms));
    },
  });

  useQuery<ProposalProductsResponse>(GET_PROPOSAL_PRODUCTS, {
    onCompleted(response) {
      if (!Array.isArray(response?.products)) return;
      dispatch(deskingActions.setProducts(response.products));
    },
  });

  const [onCreateProposal, { loading: isProposalLoading }] = useMutation<
    CreateProposalResponse,
    CreateProposalVariables
  >(CREATE_PROPOSAL_V2);

  const form = useForm<FinanceProgramFormValues>({
    defaultValues: getDefaultValues(vo, creditApplication, equipmentData?.equipments?.[0]),
  });

  const handleCreateProposal = async (variables: any) => {
    try {
      await onCreateProposal({ variables });

      dispatch(deskingActions.setProposalCreated(true));
    } catch (error) {
      logError(error);
    } finally {
      dispatch(deskingActions.setProposalCreated(false));
    }
  };

  const handleSubmitForm = async ({ isDraft }: SubmitOptions) => {
    const formData = form.getValues();
    const paymentOptions = collectDataForCreateProposal(formData, menuByTerms);

    const variables = {
      input: {
        paymentOptions,
        sendProposal: !isDraft,
        vendorOpportunityId: vo.vendorOpportunityId,
        financeProgramId: formData.financeQuote?.program,
      },
    };

    if (isDraft) handleOpen({ onSaveAsDraft: () => handleCreateProposal(variables) });
    else await handleCreateProposal(variables);
  };

  return (
    <Box className={classes.formContainer}>
      <FormProvider {...form}>
        <FinanceProgramForm />
      </FormProvider>

      <FinanceProgramFormActions onSubmitForm={handleSubmitForm} isProposalLoading={isProposalLoading} />
    </Box>
  );
};

const useStyles = makeStyles(({ palette: { primary } }) => ({
  formContainer: {
    gap: "16px",
    display: "flex",
    flexDirection: "column",

    "& .productName": {
      textTransform: "capitalize",
    },

    "& .row": {
      gap: "8px",
      display: "flex",

      "& > *": {
        flex: 1,
      },
    },

    "& .section": {
      gap: "6px",
      display: "flex",
      flexDirection: "column",

      "& .actions": {
        gap: "2px",
        display: "flex",
        alignItems: "center",
      },

      "& .sectionHeader": {
        display: "flex",
        minHeight: "56px",
        padding: "13px 16px",
        borderRadius: "2px",
        alignItems: "center",
        backgroundColor: blue["50"],
        justifyContent: "space-between",

        "& .collapse": {
          gap: "8px",
          display: "flex",
          alignItems: "center",
        },

        "& .actionPanel": {
          gap: "8px",
          display: "flex",
          alignItems: "center",
        },

        "& .MuiButton-containedPrimary": {
          borderRadius: "var(--borderRadius, 4px)",
          background: "var(--common-white_states-main, #FFF)",
          boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)",

          "& > span.MuiButton-label": {
            color: primary.main,
          },
        },
      },

      "& .sectionFields": {
        gap: "8px",
        display: "flex",
        flexDirection: "column",
      },
    },
  },
}));
