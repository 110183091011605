import gql from "graphql-tag";

export const CALCULATE_MULTIPLE_PAYMENTS = gql`
  query CalculateMultiplePayments($input: [CalculateMultiplePaymentsInput]) {
    calculateMultiplePayments(input: $input) {
      criteria {
        amountFinanced
        paymentPeriodTiming
        residualPercentage
        salesTaxPercentage
        numberOfAdvancePayments
        downPaymentAmount
        flatFees {
          fee
          identifier
        }
        markups {
          identifier
          markupPercentage
        }
        menu {
          name
          description
          menuOptions {
            name
            payment {
              paymentAmountPerPeriod
              financeProfit
            }
            description
            titleColor
            titleBgColor
            products {
              title
            }
            ordinal
          }
        }
      }
      paymentOptions {
        paymentAmountPerPeriod
        dealerMarkup
        financeProfit
        paymentAmountPerPeriodWithoutDealerMarkup
        rate
        term
        customerRate
        subsidyAmount
        subsidyPercentage
        menu {
          name
          proposalMenuId
          description
          menuOptions {
            name
            description
            payment {
              paymentAmountPerPeriod
              financeProfit
              dealerMarkup
              paymentAmountPerPeriodWithoutDealerMarkup
              rate
              term
              customerRate
              subsidyAmount
              subsidyPercentage
            }
            titleColor
            titleBgColor
            products {
              cost
              title
              addons {
                category
                cost
                markup {
                  markup
                  type
                }
                retailCost
                thirdPartyId
                title
              }
              markup {
                markup
                type
              }
              criteria
              retailCost
              thirdPartyUrl
              proposalProductId
            }
          }
        }
        term
      }
    }
  }
`;
