import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MarkupValues } from "./MarkupValues";
import { formatCurrency } from "../../../../../utils";
import { CommonMenuPriceValues } from "../../../types";

export const CardPriceSimple = ({ markup, retailCost, cost }: CommonMenuPriceValues) => {
  const classes = useStyles();

  return (
    <Box className={classes.cardPrice}>
      <Box className="priceBlock">
        <Typography component="span" variant="subtitle2" color="textSecondary">
          Cost
        </Typography>

        <Typography component="span">{formatCurrency(cost, true)}</Typography>
      </Box>

      <Box className="priceBlock">
        <Typography component="span" variant="subtitle2" color="textSecondary">
          Markup
        </Typography>

        <Typography component="span">
          <MarkupValues markup={markup} />
        </Typography>
      </Box>

      <Box className="priceBlock">
        <Typography component="span" variant="subtitle2" color="textSecondary">
          Price
        </Typography>

        <Typography component="span">{formatCurrency(retailCost, true)}</Typography>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  cardPrice: {
    gap: "4px",
    display: "flex",
    justifyContent: "flex-start",

    "& .priceBlock": {
      padding: "4px",
      display: "flex",
      borderRadius: "2px",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
});
