import React from "react";
import _ from "lodash";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { formatNumberWithDelimiter } from "../../../../../../../utils";
import { floatNumberRegexp } from "../../../../../../../pages/Prequal/constants";
import { FormCheckbox, FormInput, FormInputSelect } from "../../../../../../../components/form";

export const DynamicFields = ({ factsToCheck, productConfigurationRulesEngine, facts, setFacts }: any) => {
  const classes = useStyles();

  const renderFieldByType: any = {
    numeric: (fact: any) => (
      <FormInput
        variant="standard"
        label={fact.factKey === "DEAL_SIZE" ? "AMOUNT ($)" : fact.factKey.replace(/_/g, " ")}
        value={formatNumberWithDelimiter(facts[fact.factKey], true)}
        onChange={value => {
          if (floatNumberRegexp.test(value)) {
            const parsedValue = parseFloat(value.replace(/[^0-9.]/g, ""));
            console.log(`adding fact: ${parsedValue}`);
            productConfigurationRulesEngine.current.addFact(fact.factKey, parsedValue);
            setFacts((prev: any) => ({ ...prev, [fact.factKey]: parsedValue }));
          }
        }}
      />
    ),
    string: (fact: any) => (
      <>
        {fact.options ? (
          <FormInputSelect
            variant="standard"
            value={facts[fact.factKey]}
            label={fact.factKey.replace(/_/g, " ")}
            options={[
              { label: "None of these / I don't Know", value: "None of These" },
              ..._.orderBy(fact.options, "asc").map(option => ({ label: option, value: option })),
            ]}
            onChange={value => {
              productConfigurationRulesEngine.current.addFact(fact.factKey, value);
              setFacts((prev: any) => ({ ...prev, [fact.factKey]: value }));
            }}
          />
        ) : (
          <FormInput
            variant="standard"
            value={facts[fact.factKey]}
            label={fact.factKey.replace(/_/g, " ")}
            onChange={value => {
              productConfigurationRulesEngine.current.addFact(fact.factKey, value);
              setFacts((prev: any) => ({ ...prev, [fact.factKey]: value }));
            }}
          />
        )}
      </>
    ),
    boolean: (fact: any) => (
      <FormCheckbox
        checked={facts[fact.factKey] === "true"}
        label={fact.factKey.replace(/_/g, " ")}
        onChange={e => {
          productConfigurationRulesEngine.current.addFact(fact.factKey, e.target.checked ? "true" : "false");
          setFacts((prev: any) => ({ ...prev, [fact.factKey]: e.target?.checked ? "true" : "false" })); // This breaks sometimes
        }}
      />
    ),
  };

  return (
    <Box className={classes.fields}>
      {factsToCheck
        .filter((fact: any) => fact.factKey !== "DEAL_SIZE")
        .map((fact: any) => renderFieldByType[fact.type](fact))}
    </Box>
  );
};

const useStyles = makeStyles({
  fields: {
    gap: "4px",
    display: "flex",
    flexDirection: "column",
  },
});
