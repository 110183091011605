import gql from "graphql-tag";

export const GET_PROPOSAL_MENUS_SELECT_OPTIONS = gql`
  query {
    proposalMenus(input: {}) {
      value: proposalMenuId
      label: name
    }
  }
`;
