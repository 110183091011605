import React from "react";
import { useDispatch } from "react-redux";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import { Box, IconButton, Typography } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { ProposalProduct } from "@trnsact/trnsact-shared-types/dist/generated";
import { ProposalProductAddon } from "@trnsact/trnsact-shared-types/src/generated";
import { deskingActions } from "../../../model";
import { CardPriceBlock } from "../cardPriceBlock";
import { ProposalProductCardAddons } from "./ProposalProductCardAddons";

interface Props {
  menuType: string;
  product: ProposalProduct;
  onRemoveProduct: VoidFunction;
}

export const ProposalProductCard = ({ product, onRemoveProduct, menuType }: Props) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleRemoveAddon = (category: string) => {
    dispatch(
      deskingActions.removeMenuProductAddon({
        productId: product.proposalProductId,
        category,
        menuType,
      })
    );
  };

  return (
    <Box className={classes.card}>
      <Box className={classes.title}>
        <Typography className="productName" variant="h6">
          {product.title}
        </Typography>

        <Box className="actions">
          <InfoOutlined color="primary" />

          <IconButton
            size="small"
            onClick={event => {
              event.stopPropagation();
              onRemoveProduct();
            }}
          >
            <DeleteOutlineIcon color="error" />
          </IconButton>
        </Box>
      </Box>

      <CardPriceBlock type="simple" cost={product.cost} markup={product.markup} retailCost={product.retailCost} />

      {!!(product as any)?.configuration?.addons?.length && (
        <ProposalProductCardAddons
          onRemoveAddon={handleRemoveAddon}
          addons={(product as any)?.configuration?.addons as ProposalProductAddon[]}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  card: {
    gap: "8px",
    display: "flex",
    padding: "8px",
    borderRadius: "2px",
    flexDirection: "column",
    backgroundColor: grey["100"],
  },
  title: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
});
