import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { ValueOf } from "../../../../../../global";
import { deskingSelectors } from "../../../../model";
import { FormSectionHeader } from "../../../components";
import { creditAppSelectors } from "../../../../../../redux/creditAppReducer";
import { FinanceProgramFormValues, FormSectionProps } from "../../../../types";
import { FormInputSelect, InputField } from "../../../../../../components/form";

export const Customer = ({ sectionName, onUpdateCollapse }: FormSectionProps) => {
  const { control, setValue } = useFormContext<FinanceProgramFormValues>();
  const { customer } = useWatch({ control });

  const contactSelectOptions = useSelector(creditAppSelectors.contactSelectOptions);
  const contact = useSelector((state: any) => creditAppSelectors.contact(state, customer?.customerSearch));
  const isSectionOpen = useSelector((state: any) => deskingSelectors.isSectionOpen(state, sectionName));

  const handleChangeCustomer = (value: string) => {
    const dependentFields: (keyof ValueOf<Pick<FinanceProgramFormValues, "customer">, "customer">)[] = [
      "title",
      "email",
      "phone",
      "lastName",
      "firstName",
    ];

    setValue("customer.customerSearch", value);
    dependentFields.forEach(field => setValue(`customer.${field}`, contact?.[field] ?? ""));
  };

  return (
    <Box className="section">
      <FormSectionHeader
        name="Customer"
        sectionName={sectionName}
        isSectionOpen={isSectionOpen}
        onUpdateCollapse={onUpdateCollapse}
      />

      <Box className="sectionFields">
        <Controller
          control={control}
          name="customer.customerSearch"
          render={({ field }) => (
            <FormInputSelect
              size="small"
              variant="standard"
              label="Select contact"
              options={contactSelectOptions}
              {...field}
              onChange={handleChangeCustomer}
            />
          )}
        />

        <Box className="row">
          <InputField label="Customer name" control={control} name="customer.name" inputProps={{ disabled: true }} />

          <InputField label="Title" control={control} name="customer.title" inputProps={{ disabled: true }} />
        </Box>

        <Box className="row">
          <InputField label="Last name" control={control} name="customer.lastName" inputProps={{ disabled: true }} />

          <InputField label="First name" control={control} name="customer.firstName" inputProps={{ disabled: true }} />
        </Box>

        <Box className="row">
          <InputField label="Email" control={control} name="customer.email" inputProps={{ disabled: true }} />

          <InputField label="Phone number" control={control} name="customer.phone" inputProps={{ disabled: true }} />
        </Box>
      </Box>
    </Box>
  );
};
